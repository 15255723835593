import { JobPlannerForm } from '@connected/job-planner-v2/visualization/ui';
import { createAction, props } from '@ngrx/store';
import {
  CraneInformation,
  PalChartConfiguration,
  PalChartCurveResult,
} from '@paldesk/shared-lib/data-access/palchart-service-generated';

export namespace JobPlannerActions {
  // initialize
  export const InitializePalChart = createAction(
    '[JobPlanner] INITIALIZE_PAL_CHART',
    props<{ payload: string }>(),
  );
  export const InitializePalChartSuccess = createAction(
    '[JobPlanner] INITIALIZE_PAL_CHART_SUCCESS',
    props<{ payload: CraneInformation }>(),
  );
  export const InitializePalChartError = createAction(
    '[JobPlanner] INITIALIZE_PAL_CHART_ERROR',
    props<{ payload: any }>(),
  );

  // initialize
  export const GetOutriggers = createAction(
    '[JobPlanner] GET_OUTRIGGERS',
    props<{ payload: string }>(),
  );
  export const GetOutriggersSuccess = createAction(
    '[JobPlanner] GET_OUTRIGGERS_SUCCESS',
    props<{ payload: PalChartConfiguration }>(),
  );
  export const GetOutriggersError = createAction(
    '[JobPlanner] GET_OUTRIGGERS_ERROR',
  );

  // configure
  export const ConfigurePalChart = createAction(
    '[JobPlanner] CONFIGURE_PAL_CHART',
    props<{ payload: PalChartConfiguration }>(),
  );

  export const SetStabilizerValue = createAction(
    '[JobPlanner] SET_STABILIZER_VALUE',
    props<{ payload: { id: number; value: number } }>(),
  );

  export const SetStabilizerState = createAction(
    '[JobPlanner] SET_STABILIZER_STATE',
    props<{ payload: { id: number; state: boolean } }>(),
  );

  // form
  export const SetForm = createAction(
    '[JobPlanner] SET_FORM',
    props<{ payload: JobPlannerForm }>(),
  );

  // calculate
  export const CalculatePalChart = createAction(
    '[JobPlanner] CALCULATE_PAL_CHART',
  );
  export const CalculatePalChartSuccess = createAction(
    '[JobPlanner] CALCULATE_PAL_CHART_SUCCESS',
    props<{ payload: PalChartCurveResult[] }>(),
  );
  export const CalculatePalChartError = createAction(
    '[JobPlanner] CALCULATE_PAL_CHART_ERROR',
  );
}
