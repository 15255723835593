<ng-container [gaCategoryGroup]="'Sidebar'">
  <!-- Header -->
  <div
    class="header padding"
    [style.background-image]="'url(assets/images/background_red.png)'"
  >
    <div class="container">
      <img
        (click)="reload()"
        src="assets/images/logo_white.png"
        [gaClickListener]="'Reload'"
      />
      <a
        mat-icon-button
        [routerLink]="['/settings']"
        *ngIf="userService.isAuthorized"
        [gaClickListener]="'Navigate to Settings'"
      >
        <mat-icon>settings</mat-icon>
      </a>
    </div>
    <div class="container">
      <ng-container
        *ngIf="
          userService.isAuthorized && (userService.currentUser | async);
          else noLogin
        "
      >
        <span *pdLet="userService.currentUser | async as user">
          <strong data-cy="user-name">{{
            'sign_in.hello_client'
              | translate: { name: user.firstname + ' ' + user.lastname }
          }}</strong>
          <br />
          <span>{{ equipmentNumber }}</span>
        </span>
        <a
          (click)="userService.logout()"
          *ngIf="userService.isAuthorized"
          class="no-underline"
          [gaClickListener]="'Logout'"
          >{{ 'sign_in.sign_out' | translate }}</a
        >
      </ng-container>
      <ng-template #noLogin
        ><a
          class="no-underline"
          (click)="userService.login()"
          [gaClickListener]="'Login'"
          >{{ 'sign_in.sign_in' | translate }}</a
        ></ng-template
      >
    </div>
  </div>

  <!-- Navigation -->
  <mat-nav-list *pdLet="activeAlertsCount$ | async as activeAlertsCount"
    ><ng-container
      *pdLet="hasEquipmentAssigned$ | async as hasEquipmentAssigned"
    >
      <a
        mat-list-item
        routerLinkActive="list-item-active"
        [routerLinkActiveOptions]="{ exact: false }"
        [routerLink]="userService.isAuthorized ? ['/dashboard'] : ['/landing']"
        [gaClickListener]="
          userService.isAuthorized
            ? 'Navigate to Dashboard'
            : 'Navigate to Landing'
        "
        [attr.data-cy]="
          userService.isAuthorized
            ? 'dashboard-navigation'
            : 'landing-navigation'
        "
      >
        <mat-icon
          matListItemIcon
          fontSet="pd"
          fontIcon="pd-equipment-status"
        ></mat-icon>
        <p matListItemTitle>
          {{
            (userService.isAuthorized
              ? 'menu_item.dashboard'
              : 'menu_item.home'
            ) | translate
          }}
        </p>
      </a>
      <a
        mat-list-item
        routerLinkActive="list-item-active"
        [routerLinkActiveOptions]="{ exact: false }"
        [routerLink]="
          !userService.isAuthorized || !hasMCL || !hasEquipmentAssigned
            ? null
            : ['/maintenance']
        "
        [class.disabled]="
          !userService.isAuthorized || !hasMCL || !hasEquipmentAssigned
        "
        [disabled]="
          !userService.isAuthorized || !hasMCL || !hasEquipmentAssigned
        "
        [gaClickListener]="'Navigate to Maintenance'"
        data-cy="maintenance-navigation"
      >
        <mat-icon
          matListItemIcon
          fontSet="pd"
          fontIcon="pd-maintenance-checklist"
        ></mat-icon>

        <p matListItemTitle>
          {{ 'menu_item.maintentance_checklist' | translate }}
        </p>
      </a>
      <a
        mat-list-item
        routerLinkActive="list-item-active"
        [routerLinkActiveOptions]="{ exact: false }"
        [routerLink]="
          !userService.isAuthorized || !hasEquipmentAssigned
            ? null
            : ['/documents']
        "
        [class.disabled]="!userService.isAuthorized || !hasEquipmentAssigned"
        [disabled]="!userService.isAuthorized || !hasEquipmentAssigned"
        [gaClickListener]="'Navigate to Documents'"
        data-cy="documents-navigation"
      >
        <mat-icon class="info-icon" matListItemIcon>description</mat-icon>
        <p matListItemTitle>
          {{ 'header.search.result_documents' | translate }}
        </p>
      </a>
      <a
        mat-list-item
        routerLinkActive="list-item-active"
        [routerLinkActiveOptions]="{ exact: false }"
        [routerLink]="['/salesandservicesearch']"
        [gaClickListener]="'Navigate to Sales and Service search'"
        data-cy="sales-service-navigation"
      >
        <mat-icon
          matListItemIcon
          fontSet="pd"
          fontIcon="pd-service-partner-search"
        ></mat-icon>
        <p matListItemTitle>
          {{ 'menu_item.service_contact' | translate }}
        </p>
      </a>
      <a
        mat-list-item
        routerLinkActive="list-item-active"
        [routerLinkActiveOptions]="{ exact: false }"
        [routerLink]="['/codes']"
        [gaClickListener]="'Navigate to Palcode'"
        data-cy="palcode-navigation"
      >
        <mat-icon matListItemIcon fontSet="pd" fontIcon="pd-alerts"></mat-icon>
        <p
          matListItemTitle
          [matBadge]="activeAlertsCount"
          matBadgeColor="primary"
          [matBadgeHidden]="!(activeAlertsCount > 0)"
        >
          {{ 'menu_item.alerts' | translate }}
        </p>
      </a>
      <a
        mat-list-item
        routerLinkActive="list-item-active"
        [routerLinkActiveOptions]="{ exact: false }"
        [routerLink]="
          !userService.isAuthorized || !isCrane || !hasEquipmentAssigned
            ? null
            : ['/job-planner']
        "
        [class.disabled]="
          !userService.isAuthorized || !isCrane || !hasEquipmentAssigned
        "
        [disabled]="
          !userService.isAuthorized || !isCrane || !hasEquipmentAssigned
        "
        [gaClickListener]="'Navigate to Job Planner'"
        data-cy="job-planner-navigation"
      >
        <mat-icon
          matListItemIcon
          fontSet="pd"
          fontIcon="pd-stability-chart"
        ></mat-icon>

        <p matListItemTitle>
          {{ 'menu_item.palchart' | translate }}
        </p>
      </a>
      <a
        mat-list-item
        routerLinkActive="list-item-active"
        [routerLinkActiveOptions]="{ exact: false }"
        [routerLink]="
          (!userService.isAuthorized ||
            !(isCommunicationEnabled && hasTelematic) ||
            !hasEquipmentAssigned) &&
          contractType !== connectedContractType.ConnectedPlus
            ? null
            : ['/system-communication']
        "
        [class.disabled]="
          (!userService.isAuthorized ||
            !(isCommunicationEnabled && hasTelematic) ||
            !hasEquipmentAssigned) &&
          contractType !== connectedContractType.ConnectedPlus
        "
        [disabled]="
          (!userService.isAuthorized ||
            !(isCommunicationEnabled && hasTelematic) ||
            !hasEquipmentAssigned) &&
          contractType !== connectedContractType.ConnectedPlus
        "
        [gaClickListener]="'Navigate to System Communication'"
        data-cy="system-communication-navigation"
      >
        <mat-icon class="info-icon" matListItemIcon>info</mat-icon>
        <p matListItemTitle>
          {{ 'menu_item.system_communication' | translate }}
        </p>
      </a>
    </ng-container>
    <mat-divider></mat-divider>
    <a
      mat-list-item
      routerLinkActive="list-item-active"
      [routerLink]="['/info']"
      [gaClickListener]="'Navigate to Info'"
      data-cy="info-navigation"
    >
      <mat-icon matListItemIcon>info</mat-icon>
      <p matListItemTitle>{{ 'menu_item.info' | translate }}</p>
    </a>
  </mat-nav-list>
</ng-container>
