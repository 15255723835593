import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  CommunicationService,
  CraneOutriggerResponse,
} from '@connected/data-access/fleet-service';
import {
  PalChartConfiguration,
  PalChartService,
} from '@paldesk/shared-lib/data-access/palchart-service-generated';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { OperatorMonitorState } from '..';
import { JobPlannerActions } from './job-planner.actions';
import { JobPlannerSelectors } from './job-planner.selectors';

@Injectable()
export class JobPlannerEffects {
  private actions$: Actions = inject(Actions);
  private store: Store<OperatorMonitorState> = inject(Store);

  InitializePalChart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobPlannerActions.InitializePalChart),
      switchMap(({ payload }) =>
        this.palChartService.getCraneInformation(payload).pipe(
          map((data) =>
            JobPlannerActions.InitializePalChartSuccess({ payload: data }),
          ),
          catchError((err) => [
            JobPlannerActions.InitializePalChartError({ payload: err.error }),
          ]),
        ),
      ),
    ),
  );

  GetOutriggers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobPlannerActions.GetOutriggers),
      withLatestFrom(this.store.select(JobPlannerSelectors.getConfig)),
      switchMap(([{ payload }, config]) =>
        this.communicationService.getCraneOutriggers(String(payload)).pipe(
          map((data) =>
            JobPlannerActions.GetOutriggersSuccess({
              payload: this.setOutriggers(config, data),
            }),
          ),
          catchError(() => [JobPlannerActions.GetOutriggersError()]),
        ),
      ),
    ),
  );

  CalculatePalChart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(JobPlannerActions.CalculatePalChart),
      withLatestFrom(this.store.select(JobPlannerSelectors.getConfig)),
      switchMap(([, config]) =>
        this.palChartService.getPalChartCurveResults(config).pipe(
          map((data) =>
            JobPlannerActions.CalculatePalChartSuccess({ payload: data }),
          ),
          catchError(() => [JobPlannerActions.CalculatePalChartError()]),
        ),
      ),
    ),
  );

  constructor(
    private palChartService: PalChartService,
    private communicationService: CommunicationService,
  ) {}

  // map outrigger percentage to config
  setOutriggers(
    config: PalChartConfiguration,
    outriggers: CraneOutriggerResponse,
  ) {
    const cloneConfig: PalChartConfiguration = {
      ...config,
    };
    outriggers.outrigger_data?.forEach((x) => {
      const point = cloneConfig.supportPoints?.find(
        (c) => c.stabilizerId === x.stabilizer_id,
      );
      if (point) {
        point.value = x.percentage ? x.percentage / 100 : 0;
      }
    });

    cloneConfig.lca03 = !!outriggers.additional?.lca_03;
    cloneConfig.lca05 = !!outriggers.additional?.lca_05;
    cloneConfig.lca06 = !!outriggers.additional?.lca_06;
    return cloneConfig;
  }
}
