import { EquipmentDetailsResponse } from '@connected/fleet-service/models';
import { createAction, props } from '@ngrx/store';

export namespace ConnectedActions {
  export const LoadEquipmentDetail = createAction(
    '[CONNECTED] LOAD_EQUIPMENT_DETAIL',
    props<{ equipmentNumber: string }>(),
  );
  export const LoadEquipmentDetailSuccess = createAction(
    '[CONNECTED] LOAD_EQUIPMENT_DETAIL_SUCCESS',
    props<{ payload: EquipmentDetailsResponse }>(),
  );
  export const LoadEquipmentDetailError = createAction(
    '[CONNECTED] LOAD_EQUIPMENT_DETAIL_ERROR',
  );
  export const SetSCCURL = createAction(
    '[CONNECTED] SET_SCC_URL',
    props<{ sccUrl: string }>(),
  );
}
