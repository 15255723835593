import { createSelector } from '@ngrx/store';
import { OperatorMonitorState } from '../';

const selectDashboard = (state: OperatorMonitorState) => state.dashboard;

export namespace DashboardSelectors {
  export const getEquipmentDetail = createSelector(
    selectDashboard,
    (state) => state.detail,
  );
  export const getPartnerInfo = createSelector(
    selectDashboard,
    (state) => state.detail?.service_partner_info,
  );

  export const getService = createSelector(
    selectDashboard,
    (state) => state.service,
  );

  export const getIsEquipmentDetailLoading = createSelector(
    selectDashboard,
    (state) => state.isEquipmentDetailLoading,
  );

  export const getIsServiceLoading = createSelector(
    selectDashboard,
    (state) => state.isServiceLoading,
  );

  export const getHasServiceFailed = createSelector(
    selectDashboard,
    (state) => state.hasServiceFailed,
  );

  export const getHasEquipmentDetailFailed = createSelector(
    selectDashboard,
    (state) => state.hasEquipmentDetailFailed,
  );
}
