import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'opm-error-info',
  templateUrl: './error-info.component.html',
  styleUrls: ['./error-info.component.scss'],
})
export class ErrorInfoComponent implements OnInit {
  @Input() highlight = false;
  @Input() errorText = '';
  @Input() hasTelematics = true;
  @Input() title = null;
  showOfflineMessage: boolean;

  ngOnInit() {
    this.showOfflineMessage = !navigator.onLine;
  }
}
