import { Pipe, PipeTransform } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';

/**
 * Parse a youtube url and extract the video id from it t
 * then build a embed url which can be used in I-Frames
 * Supported Formats (as well with https)
 * http://www.youtube.com/watch?v=0zM3nApSvMg&feature=feedrec_grec_index
 * http://www.youtube.com/user/IngridMichaelsonVEVO#p/a/u/1/QdK8U-VIH_o
 * http://www.youtube.com/v/0zM3nApSvMg?fs=1&amp;hl=en_US&amp;rel=0
 * http://www.youtube.com/watch?v=0zM3nApSvMg#t=0m10s
 * http://www.youtube.com/embed/0zM3nApSvMg?rel=0
 * http://www.youtube.com/watch?v=0zM3nApSvMg
 * http://youtu.be/0zM3nApSvMg
 */
@Pipe({ name: 'pdYouTubeUrl' })
export class YouTubeUrlPipe implements PipeTransform {
  transform(url?: string): SafeResourceUrl | null {
    if (url) {
      const youTubeUrl = 'https://www.youtube.com/embed';
      const regExp =
        /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);
      if (match && match[2].length === 11) {
        return youTubeUrl + '/' + match[2];
      } else {
        return url;
      }
    }
    return null;
  }
}
