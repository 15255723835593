import { SupportPointsLight } from '@paldesk/shared-lib/data-access/palchart-service-generated';

export const PALCHART_SCALE_FACTOR = 0.188976137139952;
export class JobPlannerUtils {
  static isFrontOrBackStabilizer(id: number) {
    return (
      !this.isWheel(id) &&
      !this.isLeftStabilizer(id) &&
      !this.isRightStabilizer(id)
    );
  }

  static isWheel(id: number) {
    return id <= 4;
  }

  static isLeftStabilizer(id: number) {
    return id === 9 || id === 11;
  }

  static isRightStabilizer(id: number) {
    return id === 10 || id === 12;
  }

  static stabilizerIsExtendable(stabilizer: SupportPointsLight) {
    // a extenalbe stabilizer can be moved one of these ways
    return (
      stabilizer &&
      (stabilizer.x !== stabilizer.x2 || stabilizer.y !== stabilizer.y2)
    );
  }

  static getXAndY(
    x: number,
    y: number,
    offset: { x: number; y: number },
  ): string {
    return (
      x * -1 * PALCHART_SCALE_FACTOR +
      offset.x +
      ',' +
      (y * PALCHART_SCALE_FACTOR + offset.y)
    );
  }
}
