import { createAction, props } from '@ngrx/store';

export namespace FeatureFlagActions {
  export const loadSapIssueFeature = createAction(
    '[FEATURE FLAG] LOAD_SAP_ISSUE',
  );

  export const loadSapIssueFeatureSuccess = createAction(
    '[FEATURE FLAG] LOAD_SAP_ISSUE_SUCCESS',
    props<{ payload: boolean }>(),
  );

  export const loadSapIssueFeatureError = createAction(
    '[FEATURE FLAG] LOAD_SAP_ISSUE_ERROR',
  );
}
