import { createFeatureSelector, createSelector } from '@ngrx/store';
import { contractsKey, ContractsState } from './contracts.reducers';

const selectContracts = createFeatureSelector<ContractsState>(contractsKey);

export namespace ContractsSelectors {
  export const upgradableEquipments = createSelector(
    selectContracts,
    (state) => state.upgradableContracts,
  );
  export const getUpgradableEquipment = (equipmentNumber: string) =>
    createSelector(selectContracts, (state) =>
      state.upgradableContracts.find(
        (contract) => contract.equipment_number === equipmentNumber,
      ),
    );
  export const getContractsOverview = createSelector(
    selectContracts,
    (state) => state.contractsOverview,
  );
  export const getContractsOverviewLoading = createSelector(
    selectContracts,
    (state) => state.isContractsOverviewLoading,
  );
  export const getContractsOverviewFailed = createSelector(
    selectContracts,
    (state) => state.hasContractsOverviewFailed,
  );
  export const getContractsFilterSetup = createSelector(
    selectContracts,
    (state) => state.contractOverviewfilterSetup,
  );
  export const getContractOverviewSort = createSelector(
    selectContracts,
    (state) => state.contractOverviewSort,
  );

  export const getConnectedContracts = createSelector(
    selectContracts,
    (state) => state.connectedContracts,
  );
}
