import { createAction, props } from '@ngrx/store';

export namespace SettingsActions {
  export const SetDefaultEquipment = createAction(
    '[Settings] SET_DEFAULT_EQUIPMENT',
    props<{ payload: string }>(),
  );
  export const SetUseImperialUnits = createAction(
    '[Settings] SET_USE_IMPERIAL_UNITS',
    props<{ payload: boolean }>(),
  );
  export const SetInstallPromptDate = createAction(
    '[Settings] SET_INSTALL_PROMPT_DATE',
    props<{ payload: Date }>(),
  );
}
