import { createSelector } from '@ngrx/store';
import { OperatorMonitorState } from '..';

const selectJobPlanner = (state: OperatorMonitorState) => state.jobPlanner;

export namespace JobPlannerSelectors {
  export const getSteps = createSelector(
    selectJobPlanner,
    (state) => state.steps,
  );

  export const getData = createSelector(
    selectJobPlanner,
    (state) => state.data,
  );

  export const getConfig = createSelector(
    selectJobPlanner,
    (state) => state?.config,
  );

  export const getResult = createSelector(
    selectJobPlanner,
    (state) => state.result,
  );

  export const getForm = createSelector(
    selectJobPlanner,
    (state) => state.form,
  );

  export const getIsOutriggersLoading = createSelector(
    selectJobPlanner,
    (state) => state.isOutriggersLoading,
  );

  export const getIsDataLoading = createSelector(
    selectJobPlanner,
    (state) => state.isDataLoading,
  );

  export const getHasOutriggersFailed = createSelector(
    selectJobPlanner,
    (state) => state.hasOutriggersFailed,
  );

  export const getIsResultLoading = createSelector(
    selectJobPlanner,
    (state) => state.isResultLoading,
  );

  export const getHasResultFailed = createSelector(
    selectJobPlanner,
    (state) => state.hasResultFailed,
  );

  export const getInitializationError = createSelector(
    selectJobPlanner,
    (state) => state.error,
  );
}
