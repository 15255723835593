import { Pipe, PipeTransform } from '@angular/core';
import { PalChartCurveResult } from '@paldesk/shared-lib/data-access/palchart-service-generated';
import { JobPlannerUtils } from '../../utils/job-planner-utils';

@Pipe({
  name: 'getResultPath',
  standalone: true,
})
export class GetResultPathPipe implements PipeTransform {
  transform(
    result: PalChartCurveResult[],
    offset: { x: number; y: number },
  ): string {
    return (
      'M ' +
      result
        .map((r) => JobPlannerUtils.getXAndY(r.y, -r.x, offset))
        .join(' L ') +
      'Z'
    );
  }
}
