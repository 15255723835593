import { createSelector } from '@ngrx/store';
import { OperatorMonitorState } from '../';

const selectHeader = (state: OperatorMonitorState) => state.header;

export namespace HeaderSelectors {
  export const getSelectedEquipment = createSelector(
    selectHeader,
    (state) => state.selectedEquipment,
  );
  export const getSelectedEquipmentNumber = createSelector(
    selectHeader,
    (state) => state.selectedEquipment?.equipment_number,
  );

  export const getEquipmentList = createSelector(
    selectHeader,
    (state) => state.equipmentList,
  );
  export const getIsEquipmentListLoading = createSelector(
    selectHeader,
    (state) => state.isEquipmentListLoading,
  );
  export const getHasEquipmentListFailed = createSelector(
    selectHeader,
    (state) => state.hasEquipmentListFailed,
  );

  export const getHasEquipmentAssigned = createSelector(
    selectHeader,
    (state) => state.hasEquipmentAssigned,
  );

  export const getPpgView = createSelector(
    selectHeader,
    (state) => state.ppgView,
  );

  export const getEquipmentHasPlusContract = createSelector(
    selectHeader,
    (state) => state.equipmentHasPlusContract,
  );
}
