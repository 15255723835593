import { createAction, props } from '@ngrx/store';
import {
  DocumentsResponse,
  HybrisEquipmentResponse,
} from '@connected/data-access/fleet-service';

export namespace DocumentsActions {
  export const LoadCustomerDocuments = createAction(
    '[Documents] LOAD_CUSTOMER_DOCUMENTS',
    props<{ payload: string }>(),
  );
  export const LoadCustomerDocumentsSuccess = createAction(
    '[Documents] LOAD_CUSTOMER_DOCUMENTS_SUCCESS',
    props<{ payload: DocumentsResponse }>(),
  );
  export const LoadCustomerDocumentsError = createAction(
    '[Documents] LOAD_CUSTOMER_DOCUMENTS_ERROR',
  );

  export const UploadCustomerDocuments = createAction(
    '[Documents] UPLOAD_CUSTOMER_DOCUMENTS',
    props<{ payload: File[] }>(),
  );
  export const UploadCustomerDocumentsSuccess = createAction(
    '[Documents] UPLOAD_CUSTOMER_DOCUMENTS_SUCCESS',
    props<{ payload: string }>(),
  );
  export const UploadCustomerDocumentsError = createAction(
    '[Documents] UPLOAD_CUSTOMER_DOCUMENTS_Error',
  );

  export const DeleteCustomerDocuments = createAction(
    '[Documents] DELETE_CUSTOMER_DOCUMENTS',
    props<{ payload: string }>(),
  );
  export const DeleteCustomerDocumentsSuccess = createAction(
    '[Documents] DELETE_CUSTOMER_DOCUMENTS_SUCCESS',
    props<{ payload: string; document_id: string }>(),
  );
  export const DeleteCustomerDocumentsError = createAction(
    '[Documents] DELETE_CUSTOMER_DOCUMENTS_Error',
    props<{ payload: string }>(),
  );
  export const LoadDocuments = createAction('[Documents] LOAD_DOCUMENTS');
  export const LoadDocumentsSuccess = createAction(
    '[Documents] LOAD_DOCUMENTS_SUCCESS',
    props<{ payload: HybrisEquipmentResponse }>(),
  );
  export const LoadDocumentsError = createAction(
    '[Documents] LOAD_DOCUMENTS_ERROR',
  );
}
