<div *ngIf="isMaterial; else select">
  <mat-form-field class="full-width" [subscriptSizing]="'dynamic'">
    <mat-label>{{ placeholder }}</mat-label>
    <mat-select
      [(ngModel)]="selectedLanguageCode"
      [disabled]="possibleLanguages && possibleLanguages.length === 1"
      (ngModelChange)="languageChange($event)"
    >
      <mat-option
        *ngFor="let language of possibleLanguages | orderBy: 'code'"
        [value]="language.code"
      >
        {{ language.title }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<ng-template #select>
  <mat-select
    [(ngModel)]="selectedLanguageCode"
    [disabled]="possibleLanguages && possibleLanguages.length === 1"
    (ngModelChange)="languageChange($event)"
    [title]="'tooltip.action.select_language' | translate"
  >
    <mat-option
      *ngFor="let language of possibleLanguages | orderBy: 'code'"
      [value]="language.code"
    >
      {{ language.title }}
    </mat-option>
  </mat-select>
</ng-template>
