import { createSelector } from '@ngrx/store';
import { OperatorMonitorState } from '../';
import { IMPERIAL, METRIC } from '../../data/units';

export const selectSettings = (state: OperatorMonitorState) => state.settings;

export namespace SettingsSelectors {
  export const getDefaultEquipment = createSelector(
    selectSettings,
    (state) => state.defaultEquipmentNumber,
  );

  export const getUseImperialUnits = createSelector(
    selectSettings,
    (state) => state.useImperialUnits,
  );

  export const getMeasurementSystem = createSelector(
    selectSettings,
    (state) => {
      if (state && state.useImperialUnits) {
        return IMPERIAL;
      }
      return METRIC;
    },
  );

  export const getInstallPromptLastShown = createSelector(
    selectSettings,
    (state) => state.installPromptLastShown,
  );
}
