export enum ConnectedContractTypes {
  CONNECTED_PLUS = 'Connected Plus',
  CONNECTED_PLUS_EARLY_ADOPTER = 'Connected Plus Early Adopter',
  NONE = 'None',
  DATA = 'Data',
}

export enum ContractStatusEnum {
  UNKNOWN = 0,
  ACTIVE = 1,
  EXPIRED = 2,
}
