import { createReducer, on } from '@ngrx/store';
import { DocumentsResponse } from '@connected/data-access/fleet-service';
import { DocumentsActions } from './documents.actions';
import {
  PalipediaEquipmentWithDocuments,
  mapFleetMonitorPalipediaToPalipediaEquipmentWithDocuments,
} from '@features/palipedia';

export interface DocumentsState {
  customerDocuments: DocumentsResponse;
  isCustomerDocumentsLoading: boolean;
  hasCustomerDocumentsFailed: boolean;

  documents: PalipediaEquipmentWithDocuments;
  isDocumentsLoading: boolean;
  hasDocumentsFailed: boolean;

  isUploadPending: boolean;
  areDeletePending: { [key: string]: boolean };
}

export const initialState: DocumentsState = {
  customerDocuments: {} as DocumentsResponse,
  isCustomerDocumentsLoading: true,
  hasCustomerDocumentsFailed: false,

  documents: {} as PalipediaEquipmentWithDocuments,
  isDocumentsLoading: false,
  hasDocumentsFailed: false,

  isUploadPending: false,
  areDeletePending: {},
};

const _documentsReducer = createReducer(
  initialState,

  on(DocumentsActions.LoadCustomerDocuments, (state) => ({
    ...state,
    customerDocuments: initialState.customerDocuments,
    isCustomerDocumentsLoading: true,
    hasCustomerDocumentsFailed: false,
  })),
  on(DocumentsActions.LoadCustomerDocumentsSuccess, (state, { payload }) => ({
    ...state,
    customerDocuments: payload,
    isCustomerDocumentsLoading: false,
    hasCustomerDocumentsFailed: false,
  })),
  on(DocumentsActions.LoadCustomerDocumentsError, (state) => ({
    ...state,
    isCustomerDocumentsLoading: false,
    hasCustomerDocumentsFailed: true,
  })),

  on(DocumentsActions.UploadCustomerDocuments, (state) => ({
    ...state,
    isUploadPending: true,
  })),
  on(DocumentsActions.UploadCustomerDocumentsSuccess, (state) => ({
    ...state,
    isUploadPending: false,
    isCustomerDocumentsLoading: true,
    hasCustomerDocumentsFailed: false,
  })),
  on(DocumentsActions.UploadCustomerDocumentsError, (state) => ({
    ...state,
    isUploadPending: false,
  })),

  on(DocumentsActions.DeleteCustomerDocuments, (state, { payload }) => ({
    ...state,
    areDeletePending: {
      ...state.areDeletePending,
      [payload]: true,
    },
  })),
  on(DocumentsActions.DeleteCustomerDocumentsSuccess, (state, payload) => ({
    ...state,
    areDeletePending: {
      ...state.areDeletePending,
      [payload.document_id]: false,
    },
    isCustomerDocumentsLoading: true,
    hasCustomerDocumentsFailed: false,
  })),
  on(DocumentsActions.DeleteCustomerDocumentsError, (state, { payload }) => ({
    ...state,
    areDeletePending: {
      ...state.areDeletePending,
      [payload]: false,
    },
  })),
  on(DocumentsActions.LoadDocuments, (state) => ({
    ...state,
    documents: initialState.documents,
    isDocumentsLoading: true,
    hasDocumentsFailed: false,
  })),
  on(DocumentsActions.LoadDocumentsSuccess, (state, { payload }) => ({
    ...state,
    documents:
      mapFleetMonitorPalipediaToPalipediaEquipmentWithDocuments(payload),
    isDocumentsLoading: false,
    hasDocumentsFailed: false,
  })),
  on(DocumentsActions.LoadDocumentsError, (state) => ({
    ...state,
    isDocumentsLoading: false,
    hasDocumentsFailed: true,
  })),
);

export function documentsReducer(state, action) {
  return _documentsReducer(state, action);
}
