import { Pipe, PipeTransform } from '@angular/core';
import { JobStabilizerData } from '@connected/data-access/fleet-service';
import {
  PalChartConfiguration,
  SupportPointsLight,
} from '@paldesk/shared-lib/data-access/palchart-service-generated';

@Pipe({
  name: 'getExtendPercentage',
  standalone: true,
})
export class GetExtendPercentagePipe implements PipeTransform {
  transform(
    supportPoint: SupportPointsLight | JobStabilizerData,
    config: PalChartConfiguration,
  ): number {
    const configPoint = config?.supportPoints?.find(
      (x) => x.stabilizerId === supportPoint.stabilizerId,
    );

    return configPoint?.value || 0;
  }
}
