export interface UnitConfig {
  factorToMetric: number;
  unit: string;
}

export interface MeasurementSystem {
  distance: UnitConfig[];
  weight: UnitConfig[];
}

export enum METRIC_DISTANCE_UNIT {
  mm,
  cm,
  m,
  km,
}

export enum METRIC_WEIGHT_UNIT {
  g,
  kg,
  t,
}

export const METRIC: MeasurementSystem = {
  distance: [
    {
      factorToMetric: 1000,
      unit: 'mm',
    },
    {
      factorToMetric: 100,
      unit: 'cm',
    },
    {
      factorToMetric: 1,
      unit: 'm',
    },
    {
      factorToMetric: 0.001,
      unit: 'km',
    },
  ],
  weight: [
    {
      factorToMetric: 1000,
      unit: 'g',
    },
    {
      factorToMetric: 1,
      unit: 'kg',
    },
    {
      factorToMetric: 0.001,
      unit: 't',
    },
  ],
};

export enum IMPERIAL_DISTANCE_UNIT {
  in,
  ft,
  mi,
}

export enum IMPERIAL_WEIGHT_UNIT {
  oz,
  lbs,
  tn,
}

export const IMPERIAL: MeasurementSystem = {
  distance: [
    {
      factorToMetric: 39.3701,
      unit: 'in',
    },
    {
      factorToMetric: 3.28084,
      unit: 'ft',
    },
    {
      factorToMetric: 0.000621371,
      unit: 'mi',
    },
  ],
  weight: [
    {
      factorToMetric: 35.27396,
      unit: 'oz',
    },
    {
      factorToMetric: 2.20462,
      unit: 'lbs',
    },
    {
      factorToMetric: 0.00110231,
      unit: 'tn',
    },
  ],
};
