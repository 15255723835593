<h2 mat-dialog-title>
  {{ 'new_version_info.headline' | translate }}
</h2>

<!-- Info -->
<mat-dialog-content>
  <div class="icon-container">
    <mat-icon>update</mat-icon>
  </div>
  <p>
    {{ 'new_version_info.text' | translate }}
  </p>
</mat-dialog-content>

<!-- Actions -->
<mat-dialog-actions [gaCategoryGroup]="'New Version Dialog'">
  <button
    mat-raised-button
    color="primary"
    (click)="dialogRef.close(true)"
    [gaClickListener]="'Update Version'"
  >
    {{ 'common.yes' | translate }}
  </button>
  <button
    mat-raised-button
    (click)="dialogRef.close(false)"
    [gaClickListener]="'Cancel Update'"
  >
    {{ 'common.no' | translate }}
  </button>
</mat-dialog-actions>
