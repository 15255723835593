import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@features/auth';
import { Subject } from 'rxjs/internal/Subject';
import { takeUntil } from 'rxjs/operators';
interface PromoTiles {
  icon: string;
  name: string;
  link?: string;
}
@Component({
  selector: 'opm-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit, OnDestroy {
  promoVideo = 'https://www.youtube.com/watch?v=3IdSfrH4DLg&feature=emb_logo';

  freeFeatures = [
    {
      icon: 'service-partner-search',
      name: 'menu_item.service_contact',
      link: '/salesandservicesearch',
    },
    {
      icon: 'alerts',
      name: 'menu_item.alerts',
      link: '/codes/finder',
    },
  ] as PromoTiles[];
  registeredFeatures = [
    {
      icon: 'equipment-status',
      name: 'menu_item.dashboard',
    },
    {
      icon: 'maintenance-checklist',
      name: 'menu_item.maintentance_checklist',
    },
    {
      icon: 'stability-chart',
      name: 'menu_item.palchart',
    },
  ] as PromoTiles[];

  private readonly destroy$ = new Subject<void>();

  constructor(
    public userService: UserService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.userService.isAuthorized$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isAuthorized) => {
        if (isAuthorized) this.router.navigate(['/dashboard']);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
