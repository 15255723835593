import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { DsSnackbar, DsSnackbarType } from '@design-system/feature/snackbar';
import { Subject, timer } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import {
  SettingsActions,
  SettingsSelectors,
  selectSettings,
} from './shared/store/settings';

import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { SwUpdate } from '@angular/service-worker';
import { UserService } from '@features/auth';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationInsightsService } from '@shared-lib/app-insights';
import { GaService } from '@shared-lib/google-analytics';
import { filterTruthy } from '@shared-lib/rxjs';
import { environment } from '../environments/environment';
import { InstallPromptComponent } from './install-prompt/install-prompt.component';
import { NewVersionInfoComponent } from './new-version-info/new-version-info.component';
import { NoEquipmentInfoComponent } from './no-equipment-info/no-equipment-info.component';
import { NAVBARITEMS } from './shared/data/navbar-items';
import { NavbarItem } from './shared/model/navbar-item.model';
import { OperatorMonitorState } from './shared/store';
import { AlertsActions } from './shared/store/alerts';
import { FeatureFlagActions } from './shared/store/feature-flag/feature-flag.actions';
import { HeaderSelectors } from './shared/store/header';
import { MaintenanceActions } from './shared/store/maintenance/maintenance.actions';

@Component({
  selector: 'opm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  @ViewChild('sidenav') sidenav;
  isOffline = false;
  trackingIsStarted = false;
  appStartIsTracked = false;
  navItems: NavbarItem[] = NAVBARITEMS;

  hasPpgCommunicationActive: boolean;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private swUpdate: SwUpdate,
    private dialog: MatDialog,
    private store: Store<OperatorMonitorState>,
    private gaService: GaService,
    public router: Router,
    public userService: UserService,
    private _appInsights: ApplicationInsightsService,
    private _snackbar: DsSnackbar,
    private _translateService: TranslateService,
    public location: Location,
  ) {
    this.gaService.create();

    router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.sidenav.close();
      }
    });

    this.userService.currentUser
      .pipe(filterTruthy(), takeUntil(this.destroy$))
      .subscribe((user) => {
        this.prepareMaintenanceChecklist();
        this.store.dispatch(AlertsActions.SetLanguage({ payload: user.lang }));
        this.store.dispatch(FeatureFlagActions.loadSapIssueFeature());
      });

    this.store.select(selectSettings).subscribe((settingsState) => {
      localStorage.setItem('settings_state', JSON.stringify(settingsState));
    });
    this.store
      .select(HeaderSelectors.getSelectedEquipment)
      .subscribe(
        (equipment) =>
          (this.hasPpgCommunicationActive =
            equipment?.equipment_type_icon === '003' &&
            equipment.has_telematics),
      );
    this.setUpServiceWorker();
  }
  ngOnInit() {
    this.startOnlineCheck();
    this.trackAppStart();
    this._appInsights.startTracking('FE: Operator Monitor');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  showOfflineInfo() {
    this._snackbar.queue(
      this._translateService.instant('offline_banner.text'),
      {
        type: DsSnackbarType.Error,
      },
    );
  }

  showOnlineInfo() {
    this._snackbar.queue(this._translateService.instant('online_banner.text'), {
      type: DsSnackbarType.Success,
    });
  }

  showNoEquipmentInfo() {
    this.dialog.open(NoEquipmentInfoComponent, {
      width: '500px',
    });
  }

  private setUpServiceWorker() {
    if ('serviceWorker' in navigator && environment.production) {
      navigator.serviceWorker.register('ngsw-worker.js');
    }

    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates
        .pipe(filter((evt) => evt.type === 'VERSION_READY'))
        .subscribe(() => {
          const dialogRef = this.dialog.open(NewVersionInfoComponent, {
            width: '500px',
          });
          dialogRef.afterClosed().subscribe((updateConfirmed) => {
            if (updateConfirmed) {
              window.location.reload();
            }
          });
        });
    }

    if (
      !navigator['standalone'] &&
      ['iPhone', 'iPad', 'iPod'].includes(navigator.platform)
    ) {
      this.store
        .select(SettingsSelectors.getInstallPromptLastShown)
        .pipe(filter((r) => this.isValidPrompDate(r)))
        .subscribe(() => this.openInstallPrompt());
    }
  }

  private isValidPrompDate(lastPromptDate?: Date) {
    if (lastPromptDate) {
      lastPromptDate.setDate(lastPromptDate.getDate() + 14);
      if (lastPromptDate < new Date()) {
        return true;
      }
      return false;
    }
    return true;
  }

  private openInstallPrompt() {
    this.dialog.open(InstallPromptComponent, {
      width: '500px',
    });
    this.store.dispatch(
      SettingsActions.SetInstallPromptDate({ payload: new Date() }),
    );
  }

  // check for online every second
  private startOnlineCheck() {
    timer(0, 1000).subscribe(() => {
      if (!navigator.onLine) {
        if (!this.isOffline) {
          this.showOfflineInfo();
        }
        this.isOffline = true;
      } else {
        if (this.isOffline) {
          this.showOnlineInfo();
        }
        this.isOffline = false;
      }
    });
  }

  private trackAppStart() {
    if (!this.appStartIsTracked) {
      this.appStartIsTracked = true;
    }
  }

  private prepareMaintenanceChecklist() {
    const prepareMaintenanceChecklistCalled = sessionStorage.getItem(
      'prepareMaintenanceChecklistCalled',
    );
    if (!prepareMaintenanceChecklistCalled) {
      this.store.dispatch(MaintenanceActions.PrepareMaintenanceChecklist());
    }
  }
}
