import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  SupportPoint,
  SupportPointsLight,
} from '@paldesk/shared-lib/data-access/palchart-service-generated';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { GetStabilizerValuePipe } from './pipe/get-stabilizer-value.pipe';
import { IsStabilizerIsExtendablePipe } from './pipe/is-stabilizer-expandable.pipe';
import {
  CalculateUserUnitPipe,
  MeasurementSystem,
} from '@connected/job-planner-v2/measurement-units/util';

@Component({
  selector: 'connected-stabilizer-value',
  templateUrl: './stabilizer-value.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    TranslateModule,
    IsStabilizerIsExtendablePipe,
    GetStabilizerValuePipe,
    CalculateUserUnitPipe,
  ],
})
export class ConnectedStabilizerValueComponent {
  @Input() stabilizer: SupportPointsLight;
  @Input() stabilizerConfig: SupportPoint;
  @Input() measurementSystem: MeasurementSystem;
}
