import { createSelector } from '@ngrx/store';
import { OperatorMonitorState } from '..';

export namespace DocumentsSelectors {
  const getDocumentsState = (state: OperatorMonitorState) => state.documents;

  export const getCustomerDocuments = createSelector(
    getDocumentsState,
    (state) => state.customerDocuments,
  );
  export const getIsCustomerDocumentsLoading = createSelector(
    getDocumentsState,
    (state) => state.isCustomerDocumentsLoading,
  );
  export const getHasCustomerDocumentsFailed = createSelector(
    getDocumentsState,
    (state) => state.hasCustomerDocumentsFailed,
  );

  export const getIsUploadDocumentsPending = createSelector(
    getDocumentsState,
    (state) => state.isUploadPending,
  );

  export const getAreDeleteDocumentsPending = createSelector(
    getDocumentsState,
    (state) => state.areDeletePending,
  );

  export const getDocumentsImages = createSelector(
    getDocumentsState,
    (state) => state.documents.pictures,
  );
}
