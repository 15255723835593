import { ServicePartnerInfoBaseResponse } from '@connected/fleet-service/models';

export enum TelematicState {
  Retrofitable = 'Retrofitable',
  Telematic = 'Telematic',
}

export enum UpgradeableState {
  Expired = 'Expired',
  ExpiredSoon = 'ExpiredSoon',
  NoContract = 'NoContract',
  ActiveContract = 'ActiveContract',
}
export interface UpgradeableContracts {
  carrier: string;

  equipment_number: string;

  equipment?: string | null;

  equipment_type_icon: string;

  telematic_state: TelematicState;

  upgradeable_state: UpgradeableState;

  service_partner_info?: ServicePartnerInfoBaseResponse;

  isSccValid: boolean;
}

export interface ContractsFilterSetup {
  text?: string;
  carrier?: string[] | null;
  status?: string[] | null;
  equipment_number?: string[] | null;
  equipment?: string[] | null;
  contract_category?: string[] | null;
  contract_status?: number[] | null;
  contract?: string[] | null;
  contract_type?: string[] | null;
}
