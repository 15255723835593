import { Location } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { OperatorMonitorState } from '../../shared/store';
import { AlertsActions } from '../../shared/store/alerts';

@Component({
  selector: 'opm-language-dialog',
  templateUrl: './language-dialog.component.html',
  styleUrls: ['./language-dialog.component.scss'],
})
export class LanguageDialogComponent {
  availableLanguages = ['en', 'de', 'es', 'fr', 'it', 'pt', 'ru', 'sv'];
  userLanguage: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) userLanguage: string,
    public dialogRef: MatDialogRef<LanguageDialogComponent>,
    private translateService: TranslateService,
    public location: Location,
    private store: Store<OperatorMonitorState>,
  ) {
    this.userLanguage = userLanguage;
  }

  changeUserLanguage(selectedLanguage: string) {
    if (this.location.path().includes('finder')) {
      this.store.dispatch(
        AlertsActions.SetLanguage({ payload: selectedLanguage }),
      );
    }
    if (
      selectedLanguage &&
      selectedLanguage !== this.translateService.currentLang
    ) {
      this.translateService.resetLang(selectedLanguage);
      this.translateService.use(selectedLanguage);
    }
    this.userLanguage = selectedLanguage;
    this.dialogRef.close();
  }
}
