import { Action, createReducer, on } from '@ngrx/store';
import {
  HookloaderStatusResponse,
  LastPlatformsDataResponse,
  OperatingInfo,
  PlatformsStabilizerResponse,
  UpperStructureData,
} from '@connected/data-access/fleet-service';
import { CommunicationActions } from './communication.actions';

export interface CommunicationState {
  hookloaderStatus?: HookloaderStatusResponse;
  ppgStatus?: LastPlatformsDataResponse;
  isPpgStatusLoading: boolean;
  hasNoContent: boolean;
  ppgStabilizer?: PlatformsStabilizerResponse;
  isPpgStabilizerLoading: boolean;
  ppgOperatingInformation?: OperatingInfo;
  isPpgOperatingInformationLoading: boolean;
  ppgUpperStructure?: UpperStructureData;
  isPpgUpperStructureLoading: boolean;
  isPpgUpperStructurePending: boolean;
  isLoading: boolean;
  hasFailed: boolean;
  isPending: boolean;
  isPpgStabilizerPending: boolean;
  isPpgHeightLimitationPending: boolean;
  isPpgMachineLockPending: boolean;
  outriggerRequestError?: string;
}

export const initialState: CommunicationState = {
  hookloaderStatus: undefined,
  ppgStatus: undefined,
  isPpgStatusLoading: true,
  hasNoContent: false,
  ppgStabilizer: undefined,
  ppgOperatingInformation: undefined,
  isPpgOperatingInformationLoading: true,

  ppgUpperStructure: undefined,
  isPpgUpperStructureLoading: true,
  isLoading: true,
  isPpgStabilizerLoading: true,
  hasFailed: false,
  isPending: false,
  isPpgStabilizerPending: false,
  isPpgHeightLimitationPending: false,
  isPpgMachineLockPending: false,
  isPpgUpperStructurePending: false,
};

export const reducer = createReducer(
  initialState,
  on(
    CommunicationActions.LoadPpgStatusError,
    CommunicationActions.LoadHookloaderStatusError,
    CommunicationActions.LoadOperatingInformationError,
    CommunicationActions.LoadPpgUpperStructureError,
    (state) => ({
      ...state,
      isLoading: false,
      isPpgStatusLoading: false,
      isPpgOperatingInformationLoading: false,
      isPpgUpperStructureLoading: false,
      hasFailed: true,
    }),
  ),

  on(
    CommunicationActions.SendHookloaderStatusRequest,
    CommunicationActions.SendOutriggerRequest,
    (state) => ({
      ...state,
      isPending: true,
      outriggerRequestError: undefined,
    }),
  ),
  on(
    CommunicationActions.SendHookloaderStatusRequestSuccess,
    CommunicationActions.SendHookloaderStatusRequestError,
    CommunicationActions.SendOutriggerRequestSuccess,
    (state) => ({
      ...state,
      isPending: false,
    }),
  ),

  on(CommunicationActions.SendOutriggerRequestError, (state, { payload }) => ({
    ...state,
    outriggerRequestError: payload,
    isPending: false,
  })),

  on(CommunicationActions.LoadHookloaderStatus, (state) => ({
    ...state,
    hookloaderStatus: initialState.hookloaderStatus,
    isLoading: true,
    hasFailed: false,
  })),
  on(
    CommunicationActions.LoadHookloaderStatusSuccess,
    (state, { payload }) => ({
      ...state,
      hookloaderStatus: payload,
      isLoading: false,
      hasFailed: false,
    }),
  ),
  on(CommunicationActions.LoadPpg, (state) => ({
    ...state,
    ppgStatus: initialState.ppgStatus,
    ppgStabilizer: initialState.ppgStabilizer,
    ppgOperatingInformation: initialState.ppgOperatingInformation,
    ppgUpperStructure: initialState.ppgUpperStructure,
    isPpgStatusLoading: true,
    isPpgOperatingInformationLoading: true,
    isPpgUpperStructureLoading: true,
    isLoading: true,
    isPpgStabilizerLoading: true,
    hasFailed: false,
  })),
  on(CommunicationActions.LoadPpgStatusSuccess, (state, { payload }) => ({
    ...state,
    ppgStatus: payload,
    isPpgStatusLoading: false,
    hasNoContent: false,
    isLoading: false,
    hasFailed: false,
  })),
  on(CommunicationActions.LoadPpgStatusNoContent, (state) => ({
    ...state,
    isLoading: false,
    isPpgStatusLoading: false,
    hasNoContent: true,
    hasFailed: false,
  })),
  on(CommunicationActions.LoadPpgStabilizerSuccess, (state, { payload }) => ({
    ...state,
    ppgStabilizer: payload,
    isPpgStabilizerLoading: false,
    hasFailed: false,
  })),
  on(CommunicationActions.LoadPpgStabilizerError, (state) => ({
    ...state,
    isPpgStabilizerLoading: false,
    hasFailed: true,
  })),
  on(
    CommunicationActions.LoadOperatingInformationSuccess,
    (state, { payload }) => ({
      ...state,
      ppgOperatingInformation: payload,
      isPpgOperatingInformationLoading: false,
      isLoading: false,
      hasFailed: false,
    }),
  ),

  on(CommunicationActions.SendPpgStabilizerRequest, (state) => ({
    ...state,
    isPpgStabilizerPending: true,
  })),
  on(
    CommunicationActions.SendPpgStabilizerRequestSuccess,
    CommunicationActions.SendPpgStabilizerRequestError,
    (state) => ({
      ...state,
      isPpgStabilizerPending: false,
    }),
  ),
  on(CommunicationActions.SendPpgHeightLimitationRequest, (state) => ({
    ...state,
    isPpgHeightLimitationPending: true,
  })),
  on(
    CommunicationActions.SendPpgHeightLimitationRequestError,
    CommunicationActions.SendPpgHeightLimitationRequestSuccess,
    (state) => ({
      ...state,
      isPpgHeightLimitationPending: false,
    }),
  ),
  on(CommunicationActions.SendPpgMachineLockRequest, (state) => ({
    ...state,
    isPpgMachineLockPending: true,
  })),
  on(
    CommunicationActions.SendPpgMachineLockRequestSuccess,
    CommunicationActions.SendPpgMachineLockRequestError,
    (state) => ({
      ...state,
      isPpgMachineLockPending: false,
    }),
  ),

  on(
    CommunicationActions.LoadPpgUpperStructureSuccess,
    (state, { payload }) => ({
      ...state,
      ppgUpperStructure: payload,
      isPpgUpperStructureLoading: false,
      isLoading: false,
      hasFailed: false,
    }),
  ),

  on(CommunicationActions.SendPpgUpperStructureRequest, (state) => ({
    ...state,
    isPpgUpperStructurePending: true,
  })),
  on(
    CommunicationActions.SendPpgUpperStructureRequestSuccess,
    CommunicationActions.SendPpgUpperStructureRequestError,
    (state) => ({
      ...state,
      isPpgUpperStructurePending: false,
    }),
  ),
);

export function communicationReducer(
  state: CommunicationState | undefined,
  action: Action,
) {
  return reducer(state, action);
}
