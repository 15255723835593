import { AlertHistoryQuery } from '@connected/alerts';
import { createReducer, on } from '@ngrx/store';
import {
  AlertDetailResponse,
  GetHistoryAlertsPerEquipmentCommandResult,
} from '@connected/data-access/fleet-service';
import {
  CodeResponse,
  CommonPropertyResponse,
} from '@paldesk/shared-lib/data-access/palcode-service-generated';
import { SearchPayload } from '../../model/search-payload.models';
import { AlertsActions } from './alerts.actions';

export interface AlertsState {
  details?: AlertDetailResponse[] | null;
  activeCount: number;
  isDetailsLoading: boolean;
  hasDetailsFailed: boolean;

  historyQuery?: AlertHistoryQuery;
  history?: GetHistoryAlertsPerEquipmentCommandResult;
  isHistoryLoading: boolean;
  hasHistoryFailed: boolean;

  searchInput?: SearchPayload;
  searchResult?: CodeResponse[];
  isSearchResultLoading: boolean;
  hasSearchResulFailed: boolean;

  productsFilter: string;
  hardwaresFilter: string;
  products?: CommonPropertyResponse[];
  hardwares?: CommonPropertyResponse[];
  isProductsLoading: boolean;
  isHardwareLoading: boolean;
  hasProductsFailed: boolean;
  hasHardwareFailed: boolean;

  alertTypesFilter?: string;
  selectedLanguage: string;
  requestedCodeID?: string;

  code?: CodeResponse;
  isCodeLoading: boolean;
  hasCodeFailed: boolean;
}

export const initialState: AlertsState = {
  details: [] as AlertDetailResponse[],
  activeCount: 0,
  isDetailsLoading: true,
  hasDetailsFailed: false,

  history: undefined,
  isHistoryLoading: true,
  hasHistoryFailed: false,

  searchInput: undefined,
  searchResult: undefined,
  isSearchResultLoading: false,
  hasSearchResulFailed: false,

  productsFilter: 'all',
  hardwaresFilter: 'all',
  products: undefined,
  hardwares: undefined,
  isProductsLoading: true,
  isHardwareLoading: true,
  hasProductsFailed: false,
  hasHardwareFailed: false,

  alertTypesFilter: undefined,
  selectedLanguage: 'en',
  code: undefined,
  isCodeLoading: true,
  hasCodeFailed: false,
  requestedCodeID: undefined,
};

const _alertsReducer = createReducer(
  initialState,
  on(AlertsActions.LoadHistory, (state) => ({
    ...state,
    history: undefined,
    isHistoryLoading: true,
    hasHistoryFailed: false,
  })),
  on(AlertsActions.LoadHistorySuccess, (state, { payload }) => ({
    ...state,
    history: payload,
    isHistoryLoading: false,
    hasHistoryFailed: false,
  })),
  on(AlertsActions.LoadHistoryError, (state) => ({
    ...state,
    history: undefined,
    isHistoryLoading: false,
    hasHistoryFailed: true,
  })),
  on(AlertsActions.SetQueryHistory, (state, { payload }) => ({
    ...state,
    historyQuery: payload,
  })),

  on(AlertsActions.LoadDetails, (state) => ({
    ...state,
    details: initialState.details,
    activeCount: initialState.activeCount,
    isDetailsLoading: true,
    hasDetailsFailed: false,
  })),
  on(AlertsActions.LoadDetailsSuccess, (state, { alerts }) => ({
    ...state,
    details: alerts,
    activeCount: alerts ? alerts.length : initialState.activeCount,
    isDetailsLoading: false,
    hasDetailsFailed: false,
  })),
  on(AlertsActions.LoadDetailsError, (state) => ({
    ...state,
    isDetailsLoading: false,
    hasDetailsFailed: true,
  })),

  on(AlertsActions.SetSearchInput, (state, { payload }) => ({
    ...state,
    searchInput: payload,
  })),
  on(AlertsActions.SearchCodes, (state) => ({
    ...state,
    searchResult: initialState.searchResult,
    isSearchResultLoading: true,
    hasSearchResulFailed: false,
  })),
  on(AlertsActions.SearchCodesSuccess, (state, { payload }) => ({
    ...state,
    searchResult: payload || [],
    isSearchResultLoading: false,
    hasSearchResulFailed: false,
  })),
  on(AlertsActions.SearchCodesError, (state) => ({
    ...state,
    isSearchResultLoading: false,
    hasSearchResulFailed: true,
  })),

  on(AlertsActions.LoadProducts, (state) => ({
    ...state,
    products: initialState.products,
    isProductsLoading: true,
    hasProductsFailed: false,
  })),
  on(AlertsActions.LoadProductsSuccess, (state, { payload }) => ({
    ...state,
    products: payload,
    isProductsLoading: false,
    hasProductsFailed: false,
  })),
  on(AlertsActions.LoadProductsError, (state) => ({
    ...state,
    isProductsLoading: false,
    hasProductsFailed: true,
  })),

  on(AlertsActions.LoadHardwares, (state) => ({
    ...state,
    hardwares: initialState.hardwares,
    isHardwareLoading: true,
    hasHardwareFailed: false,
  })),
  on(AlertsActions.LoadHardwaresSuccess, (state, { payload }) => ({
    ...state,
    hardwares: payload,
    isHardwareLoading: false,
    hasHardwareFailed: false,
  })),
  on(AlertsActions.LoadHardwaresError, (state) => ({
    ...state,
    isHardwareLoading: false,
    hasHardwareFailed: true,
  })),

  on(AlertsActions.ResetFilter, (state) => ({
    ...state,
    productsFilter: initialState.productsFilter,
    hardwaresFilter: initialState.hardwaresFilter,
    alertTypesFilter: initialState.alertTypesFilter,
  })),
  on(AlertsActions.FilterProducts, (state, { payload }) => ({
    ...state,
    productsFilter: payload,
  })),
  on(AlertsActions.FilterHardwares, (state, { payload }) => ({
    ...state,
    hardwaresFilter: payload,
  })),
  on(AlertsActions.FilterAlertTypes, (state, { payload }) => ({
    ...state,
    alertTypesFilter: payload,
  })),
  on(AlertsActions.LoadPublicCodeByID, (state, { payload }) => ({
    ...state,
    code: undefined,
    requestedCodeID: payload.code,
    isCodeLoading: true,
    hasCodeFailed: false,
  })),
  on(
    AlertsActions.LoadPublicCodeByIDSuccess,
    AlertsActions.LoadCodeByIDSuccess,
    (state, { payload }) => ({
      ...state,
      code: payload,
      isCodeLoading: false,
      hasCodeFailed: false,
    }),
  ),
  on(
    AlertsActions.LoadPublicCodeByIDError,
    AlertsActions.LoadCodeByIDError,
    (state) => ({
      ...state,
      code: undefined,
      isCodeLoading: false,
      hasCodeFailed: true,
    }),
  ),
  on(AlertsActions.LoadCodeByID, (state, { payload }) => ({
    ...state,
    code: undefined,
    requestedCodeID: payload,
    isCodeLoading: true,
    hasCodeFailed: false,
  })),

  on(AlertsActions.SetLanguage, (state, { payload }) => ({
    ...state,
    selectedLanguage: payload,
  })),
);

export function alertsReducer(state, action): AlertsState {
  return _alertsReducer(state, action);
}
