import { Pipe, PipeTransform } from '@angular/core';
import { JobEquipmentDetailsResponse } from '@connected/data-access/fleet-service';
import { CraneInformation } from '@paldesk/shared-lib/data-access/palchart-service-generated';
import {
  JobPlannerUtils,
  PALCHART_SCALE_FACTOR,
} from '../../utils/job-planner-utils';

@Pipe({
  name: 'getLorryBedPath',
  standalone: true,
})
export class GetLorryBedPathPipe implements PipeTransform {
  transform(
    data: CraneInformation | JobEquipmentDetailsResponse,
    offset: { x: number; y: number },
  ): string {
    const frontWheelLeft = data?.supportPoints?.find(
      (x) => x.stabilizerId === 1,
    );
    const frontWheelRight = data?.supportPoints?.find(
      (x) => x.stabilizerId === 4,
    );
    const rearWheelLeft = data?.supportPoints?.find(
      (x) => x.stabilizerId === 2,
    );

    const offsetLeft = 50;
    const offsetTop = 220;

    if (frontWheelLeft && frontWheelRight && rearWheelLeft) {
      const width =
        ((frontWheelLeft?.y || 0) - (frontWheelRight?.y || 0)) *
          PALCHART_SCALE_FACTOR +
        offsetLeft;
      const length =
        ((frontWheelLeft?.x || 0) - (rearWheelLeft?.x || 0)) *
        PALCHART_SCALE_FACTOR;

      // start at front wheel left
      let path =
        'M ' +
        JobPlannerUtils.getXAndY(
          frontWheelLeft.x || 0,
          frontWheelLeft.y || 0,
          offset,
        );
      path += 'm ' + offsetTop + ',' + offsetLeft / 2; // center and start behind front wheels
      path += ' l 0, ' + -width; // draw line to front wheel right
      path += ' l ' + length + ',0'; // draw line to rear wheel
      path += ' l 0,' + width; // draw line to other rear wheel
      path += ' l ' + -length + ',0'; // draw line to back to front wheel
      return (path += 'Z');
    } else {
      return '';
    }
  }
}
