import { createSelector } from '@ngrx/store';
import { OperatorMonitorState } from '../';

const selectCommunication = (state: OperatorMonitorState) =>
  state.communication;

export namespace CommunicationSelectors {
  export const getHookloaderStatus = createSelector(
    selectCommunication,
    (state) => state.hookloaderStatus,
  );
  export const getIsLoading = createSelector(
    selectCommunication,
    (state) => state.isLoading,
  );
  export const getHasFailed = createSelector(
    selectCommunication,
    (state) => state.hasFailed,
  );
  export const getIsPending = createSelector(
    selectCommunication,
    (state) => state.isPending,
  );
  export const getPpgStatus = createSelector(
    selectCommunication,
    (state) => state.ppgStatus,
  );
  export const getPpgStatusNoContent = createSelector(
    selectCommunication,
    (state) => state.hasNoContent,
  );
  export const getPpgStabilizer = createSelector(
    selectCommunication,
    (state) => state.ppgStabilizer,
  );
  export const getIsPpgStabilizerLoading = createSelector(
    selectCommunication,
    (state) => state.isPpgStabilizerLoading,
  );
  export const getIsPpgStabilizerPending = createSelector(
    selectCommunication,
    (state) => state.isPpgStabilizerPending,
  );
  export const getOperatingInformation = createSelector(
    selectCommunication,
    (state) => state.ppgOperatingInformation,
  );
  export const getIsPpgHeightLimitationPending = createSelector(
    selectCommunication,
    (state) => state.isPpgHeightLimitationPending,
  );
  export const getIsPpgMachineLockPending = createSelector(
    selectCommunication,
    (state) => state.isPpgMachineLockPending,
  );
  export const getOutriggerRequestError = createSelector(
    selectCommunication,
    (state) => state.outriggerRequestError,
  );
  export const getPpgUpperStructure = createSelector(
    selectCommunication,
    (state) => state.ppgUpperStructure,
  );
  export const getIsPpgUpperStructurePending = createSelector(
    selectCommunication,
    (state) => state.isPpgUpperStructurePending,
  );
  export const getIsPlatformStatusLoading = createSelector(
    selectCommunication,
    (state) => state.isPpgStatusLoading,
  );
  export const getIsPlatformOperatingInformation = createSelector(
    selectCommunication,
    (state) => state.isPpgOperatingInformationLoading,
  );
  export const getIsPlatformUpperStructureLoading = createSelector(
    selectCommunication,
    (state) => state.isPpgUpperStructureLoading,
  );
}
