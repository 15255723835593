import { createReducer, on } from '@ngrx/store';
import { ContractsActions } from './contracts.actions';
import { filterUpgradableContracts, getConnectedContracts } from '../helper';
import { ContractsFilterSetup, UpgradeableContracts } from '../models';
import { Sort } from '@angular/material/sort';
import { ContractsOverviewResponse } from '@connected/fleet-service/models';
import { mapPPI2PPG } from '@connected/shared/methods';

export const contractsKey = 'contracts';

export interface ContractsState {
  contractsOverview: ContractsOverviewResponse[];
  isContractsOverviewLoading: boolean;
  hasContractsOverviewFailed: boolean;

  contractOverviewfilterSetup: ContractsFilterSetup;
  contractOverviewSort: Sort;

  upgradableContracts: UpgradeableContracts[];
  connectedContracts: ContractsOverviewResponse[];
}

export const contractsInitialState: ContractsState = {
  contractsOverview: [] as ContractsOverviewResponse[],
  isContractsOverviewLoading: true,
  hasContractsOverviewFailed: false,
  contractOverviewfilterSetup: {} as ContractsFilterSetup,
  contractOverviewSort: {
    active: 'carrier',
    direction: 'desc',
  },
  upgradableContracts: [] as UpgradeableContracts[],
  connectedContracts: [] as ContractsOverviewResponse[],
};

const _contracts = createReducer(
  contractsInitialState,
  on(ContractsActions.LoadContracts, (state) => ({
    ...state,
    isContractsOverviewLoading: true,
    hasContractsOverviewFailed: false,
  })),
  on(ContractsActions.LoadContractsSuccess, (state, { payload }) => ({
    ...state,
    contractsOverview: payload?.map((item) =>
      mapPPI2PPG(item, 'equipment_type_icon'),
    ),
    upgradableContracts: filterUpgradableContracts(payload),
    connectedContracts: getConnectedContracts(payload),
    isContractsOverviewLoading: false,
    hasContractsOverviewFailed: false,
  })),
  on(ContractsActions.LoadContractsError, (state) => ({
    ...state,
    isContractsOverviewLoading: false,
    hasContractsOverviewFailed: true,
  })),

  on(ContractsActions.SetContractsFilter, (state, { payload }) => ({
    ...state,
    contractOverviewfilterSetup: payload,
  })),
  on(ContractsActions.SaveContractOverviewSort, (state, { payload }) => ({
    ...state,
    contractOverviewSort: payload,
  })),
);

export function contractsReducer(state, action) {
  return _contracts(state, action);
}
