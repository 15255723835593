import { createReducer, on } from '@ngrx/store';
import { SettingsActions } from './settings.actions';

export interface SettingsState {
  defaultEquipmentNumber: string;
  useImperialUnits: boolean;
  installPromptLastShown?: Date;
}

export const defaultState: SettingsState = {
  defaultEquipmentNumber: '',
  useImperialUnits: false,
  installPromptLastShown: undefined,
};

const _settingsReducer = createReducer(
  getInitalState(),
  on(SettingsActions.SetDefaultEquipment, (state, { payload }) => ({
    ...state,
    defaultEquipmentNumber: payload,
  })),
  on(SettingsActions.SetUseImperialUnits, (state, { payload }) => ({
    ...state,
    useImperialUnits: payload,
  })),
  on(SettingsActions.SetInstallPromptDate, (state, { payload }) => ({
    ...state,
    installPromptLastShown: payload,
  })),
);

export function settingsReducer(state, action): SettingsState {
  return _settingsReducer(state, action);
}

function getInitalState() {
  const localStateValue = localStorage.getItem('settings_state');
  if (localStateValue) {
    // check if local storage is intact
    let localState: any;
    try {
      localState = JSON.parse(localStateValue);
    } catch (e) {
      return defaultState;
    }

    if (typeof localState === 'object') {
      // parse date
      localState.installPromptLastShown = new Date(
        localState.installPromptLastShown,
      );
      // overwrite default state, to make sure we can change properties later
      // if a propertie has been removed it should not be saved again
      for (const propertyName in defaultState) {
        // local storage state may be missing new properties, these should be default
        if (Object.prototype.hasOwnProperty.call(localState, propertyName)) {
          defaultState[propertyName] = localState[propertyName];
        }
      }
    }
    return defaultState;
  } else {
    return defaultState;
  }
}
