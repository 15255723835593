<ng-container *ngIf="!userService.isAuthorized" [gaCategoryGroup]="'Landing'">
  <div fxLayout="column" [fxLayoutGap]="1 | dsSpacing">
    <h1 data-e2e="landing-headline">
      {{ 'operator_monitor.landing.welcome' | translate | uppercase }}
    </h1>
    <h2>
      {{ 'operator_monitor.landing.free_features' | translate | uppercase }}
    </h2>
    <div
      fxFlex="grow"
      fxLayout="row wrap"
      [fxLayoutGap]="(1 | dsSpacing) + ' grid'"
      fxLayoutAlign="start center"
      fxLayoutAlign.xs="center center"
      class="tiles"
    >
      <div *ngFor="let feature of freeFeatures">
        <mat-card
          [routerLink]="[feature.link]"
          data-e2e="free-features-button"
          [attr.data-cy]="feature.icon + '-tile'"
          class="tile-content"
          [gaClickListener]="'Navigate to ' + feature.name"
          ><mat-card-content
            fxLayout="column"
            [fxLayoutGap]="0.5 | dsSpacing"
            fxLayoutAlign="center center"
          >
            <mat-icon fontSet="pd" [fontIcon]="'pd-' + feature.icon"></mat-icon>
            <h3>
              {{ feature.name | translate }}
            </h3>
          </mat-card-content></mat-card
        >
      </div>
    </div>
    <h2>
      {{
        'operator_monitor.landing.registered_features' | translate | uppercase
      }}
    </h2>
    <div
      fxFlex="grow"
      fxLayout="row wrap"
      [fxLayoutGap]="(1 | dsSpacing) + ' grid'"
      fxLayoutAlign="start center"
      fxLayoutAlign.xs="center center"
      class="tiles"
    >
      <div *ngFor="let feature of registeredFeatures">
        <mat-card
          class="disable tile-content"
          [attr.data-cy]="feature.icon + '-tile'"
          ><mat-card-content
            fxLayout="column"
            [fxLayoutGap]="0.5 | dsSpacing"
            fxLayoutAlign="center center"
          >
            <mat-icon fontSet="pd" [fontIcon]="'pd-' + feature.icon"></mat-icon>
            <h3>
              {{ feature.name | translate }}
            </h3>
          </mat-card-content></mat-card
        >
      </div>
    </div>
    <div
      class="sticky"
      fxLayout="row"
      [fxLayoutGap]="1 | dsSpacing"
      fxLayoutAlign="stretch center"
    >
      <button
        fxFlex
        mat-raised-button
        color="primary"
        (click)="userService.login()"
        class="no-padding"
        data-cy="landing-login"
        [gaClickListener]="'Login'"
      >
        {{ 'sign_in.sign_in' | translate }}
      </button>
      <a
        fxFlex
        mat-raised-button
        class="no-padding"
        href="https://paldesk.palfinger.com/registration/end-user"
        [gaClickListener]="'NAvigate to register'"
        data-cy="register-button"
      >
        {{ 'sign_in.create_account' | translate }}
      </a>
    </div>
  </div>

  <mat-card>
    <mat-card-header
      ><mat-card-title>PALFINGER Connected</mat-card-title></mat-card-header
    >
    <mat-card-content class="no-padding">
      <iframe
        class="promo full-width"
        [src]="promoVideo | pdYouTubeUrl | pdSafe"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        data-e2e="promo-video"
        data-cy="promo-video"
      ></iframe>
    </mat-card-content>
  </mat-card>
</ng-container>
