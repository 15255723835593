// used to make sure all environments have same properties
export class EnvironmentBase {
  static palfinger_api_management = '';
  static palfinger_api_management_subs_key = '';
  static palfinger_app_gateway = '';
  static palfinger_app_gateway_connected = '';
  static palcode_public_api_key = '';
  static oidc_clientid = '';
  static oidc_scope = '';
  static alerts_polling_interval_seconds = '';
  static google_analytics_id = '';
  static google_api_key = '';
  static domainsForApiManagementSubsKey: string[] = [];
  static application_insights_key = '';

  static LINKS: {
    SECURITY_TOKEN_SERVICE: string;
    POST_LOGOUT_REDIRECT_URI: string;
    PALDESK_BASEPATH: string;
  } = {
    SECURITY_TOKEN_SERVICE: '',
    POST_LOGOUT_REDIRECT_URI: '',
    PALDESK_BASEPATH: '',
  };

  static env = '';

  static identityServerAuthConf: {
    whitelistedDomains: string[];
    whitelistedRoutes: string[];
  } = {
    whitelistedDomains: [],
    whitelistedRoutes: [],
  };
}
