<mat-card *ngIf="highlight" class="mat-elevation-z4">
  <mat-card-content>
    <ng-container *ngTemplateOutlet="error"></ng-container>
  </mat-card-content>
</mat-card>

<ng-container *ngIf="!highlight && hasTelematics">
  <ng-container *ngTemplateOutlet="error"></ng-container>
</ng-container>

<ng-container *ngIf="!highlight && !hasTelematics">
  <ng-container *ngTemplateOutlet="noTelematics"></ng-container>
</ng-container>

<ng-template #error>
  <div fxLayout fxLayoutGap="0.5rem" fxLayoutAlign="start center">
    <ds-text-indicator *ngIf="!showOfflineMessage" type="error"
      ><ng-container *ngIf="title; else noTitle">
        {{ title }}
      </ng-container>
      <ng-template #noTitle>
        {{ 'common.error.general' | translate }}
      </ng-template>
      <br />
      <small>{{
        errorText ? errorText : ('common.error.default' | translate)
      }}</small></ds-text-indicator
    >

    <ng-container *ngIf="showOfflineMessage">
      <mat-icon>cloud_off</mat-icon>
      <span>
        {{ 'common.error.offline' | translate }}<br />
        <small>{{ 'common.error.offline_text' | translate }}</small>
      </span>
    </ng-container>
  </div>
</ng-template>

<ng-template #noTelematics>
  <div fxLayout fxLayoutGap="0.5rem" fxLayoutAlign="start center">
    <strong>{{ 'common.no_telematic_feature' | translate }}</strong>
  </div>
</ng-template>
