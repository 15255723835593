import { createAction, props } from '@ngrx/store';
import { DriverEquipmentResponse } from '@connected/data-access/fleet-service';
import { PpgViewEnum } from '../../model/ppg-view.model';

export namespace HeaderActions {
  export const SelectEquipment = createAction(
    '[Header] SELECT_EQUIPMENT',
    props<{
      payload: DriverEquipmentResponse;
    }>(),
  );
  export const SetPpgView = createAction(
    '[Header] SET_PPG_VIEW',
    props<{
      payload: PpgViewEnum;
    }>(),
  );

  export const LoadEquipmentList = createAction('[Header] LOAD_EQUIPMENT_LIST');
  export const LoadEquipmentListSuccess = createAction(
    '[Header] LOAD_EQUIPMENT_LIST_SUCCESS',
    props<{ payload: DriverEquipmentResponse[] }>(),
  );
  export const LoadEquipmentListError = createAction(
    '[Header] LOAD_EQUIPMENT_LIST_ERROR',
  );
}
