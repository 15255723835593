import { JobPlannerStep } from '@connected/job-planner-v2/visualization/ui';

export const JOBPLANNERDEFAULTSTEPS: JobPlannerStep[] = [
  {
    label: 'start',
    path: 'initialization',
    isOutrigger: false,
  },
  {
    label: 'left',
    path: 'outriggers/left',
    isOutrigger: true,
  },
  {
    label: 'right',
    path: 'outriggers/right',
    isOutrigger: true,
  },
  {
    label: 'front_rear',
    path: 'outriggers/front_rear',
    isOutrigger: true,
  },
  {
    label: 'result',
    path: 'result',
    isOutrigger: false,
  },
];
