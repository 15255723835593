import { NgModule, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, Routes } from '@angular/router';
import { APPS } from '@config';
import { AuthGuard, AuthPermissions } from '@features/auth';
import { LandingComponent } from './landing/landing.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'landing',
    pathMatch: 'full',
  },
  {
    path: 'landing',
    component: LandingComponent,
  },
  {
    path: 'salesandservicesearch',
    loadChildren: () =>
      import('./sales-and-service-search/sales-and-service-search.module').then(
        (m) => m.SalesAndServiceSearchModule,
      ),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [
      (next: ActivatedRouteSnapshot) => inject(AuthGuard).canActivate(next),
    ],
    data: {
      permissions: {
        roles: [
          APPS.FLEET_MONITOR.ROLES.ADMIN,
          APPS.FLEET_MONITOR.ROLES.FLEET_OPERATOR,
        ],
      } as AuthPermissions,
    },
  },
  {
    path: 'job-planner',
    loadChildren: () =>
      import('./job-planner/job-planner.module').then(
        (m) => m.JobPlannerModule,
      ),
    canActivate: [
      (next: ActivatedRouteSnapshot) => inject(AuthGuard).canActivate(next),
    ],
    data: {
      permissions: {
        roles: [
          APPS.FLEET_MONITOR.ROLES.ADMIN,
          APPS.FLEET_MONITOR.ROLES.FLEET_OPERATOR,
        ],
      } as AuthPermissions,
    },
  },
  {
    path: 'system-communication',
    loadChildren: () =>
      import('./system-communication/system-communication.module').then(
        (m) => m.SystemCommunicationModule,
      ),
    canActivate: [
      (next: ActivatedRouteSnapshot) => inject(AuthGuard).canActivate(next),
    ],
    data: {
      permissions: {
        roles: [
          APPS.FLEET_MONITOR.ROLES.ADMIN,
          APPS.FLEET_MONITOR.ROLES.FLEET_OPERATOR,
        ],
      } as AuthPermissions,
    },
  },
  {
    path: 'documents',
    loadChildren: () =>
      import('./documents/documents.module').then((m) => m.DocumentsModule),
    canActivate: [
      (next: ActivatedRouteSnapshot) => inject(AuthGuard).canActivate(next),
    ],
    data: {
      permissions: {
        roles: [
          APPS.FLEET_MONITOR.ROLES.ADMIN,
          APPS.FLEET_MONITOR.ROLES.FLEET_OPERATOR,
        ],
      } as AuthPermissions,
    },
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./settings/settings.module').then((m) => m.SettingsModule),
    canActivate: [
      (next: ActivatedRouteSnapshot) => inject(AuthGuard).canActivate(next),
    ],
    data: {
      permissions: {
        roles: [
          APPS.FLEET_MONITOR.ROLES.ADMIN,
          APPS.FLEET_MONITOR.ROLES.FLEET_OPERATOR,
        ],
      } as AuthPermissions,
    },
  },
  {
    path: 'codes',
    loadChildren: () =>
      import('./alerts/alerts.module').then((m) => m.AlertsModule),
  },
  {
    path: 'maintenance',
    loadChildren: () =>
      import('./maintenance/maintenance.module').then(
        (m) => m.MaintenanceModule,
      ),
    canActivate: [
      (next: ActivatedRouteSnapshot) => inject(AuthGuard).canActivate(next),
    ],
    data: {
      permissions: {
        roles: [
          APPS.FLEET_MONITOR.ROLES.ADMIN,
          APPS.FLEET_MONITOR.ROLES.FLEET_OPERATOR,
        ],
      } as AuthPermissions,
    },
  },
  {
    path: 'info',
    loadChildren: () => import('./info/info.module').then((m) => m.InfoModule),
  },
  {
    path: 'special',
    loadChildren: () =>
      import('@design-system/feature/special-pages').then(
        (m) => m.DsSpecialPagesModule,
      ),
    data: {
      hasHeader: false,
    },
  },
  {
    path: 'callback',
    children: [],
  },
  {
    path: '**',
    redirectTo: 'special',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
