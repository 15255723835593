import {
  ErrorStateMatcher,
  MatOptionModule,
  ShowOnDirtyErrorStateMatcher,
} from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CommonModule } from '@angular/common';
import { CompanyInfoComponent } from './components/company-info/company-info.component';
import { ConnectedIndicatorComponent } from '@connected/shared/indicator/ui';
import { ContractTypePipe } from './pipes/contract-type/contract-type';
import { DsEquipmentIconModule } from '@design-system/component/equipment-icon';
import { DsLoadingModule } from '@design-system/components/loading';
import { DsPlaceholderModule } from '@design-system/components/placeholder';
import { DsSnackbarModule } from '@design-system/feature/snackbar';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsTextIndicatorModule } from '@design-system/components/text-indicator';
import { ErrorInfoComponent } from './components/error-info/error-info.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FooterComponent } from './components/footer/footer.component';
import { GoogleAnalytics4DirectiveModule } from '@shared-lib/google-analytics';
import { JoinAddressPipe } from './components/company-info/pipe/join-address.pipe';
import { LanguageSelectionModule } from '@shared-lib/language-selection';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NewLineToHtmlPipe } from './i18n/new-line-to-html.pipe';
import { NgModule } from '@angular/core';
import { PdLetDirectiveModule } from '@cdk/pd-let';
import { RouterModule } from '@angular/router';
import { SafePipeModule } from '@shared-lib/safe-pipe';
import { TranslateModule } from '@ngx-translate/core';
import { YouTubeUrlPipe } from './pipes/youtube-url.pipe';

const sharedModule = [
  // angular
  CommonModule,
  TranslateModule,
  FlexLayoutModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  // shared
  DsLoadingModule,
  ConnectedIndicatorComponent,
  GoogleAnalytics4DirectiveModule,
  PdLetDirectiveModule,
  DsEquipmentIconModule,
  DsPlaceholderModule,
  DsTextIndicatorModule,
  DsSpacingModule,
  SafePipeModule,
  LanguageSelectionModule,
  DsSnackbarModule,

  // material
  MatCardModule,
  MatDialogModule,
  MatIconModule,
  MatButtonModule,
  MatSidenavModule,
  MatTooltipModule,
  MatListModule,
  MatDividerModule,
  MatSelectModule,
  MatOptionModule,
  MatSnackBarModule,
  MatStepperModule,
  MatSlideToggleModule,
  MatRadioModule,
  MatInputModule,
  MatTabsModule,
  MatBadgeModule,
  MatCheckboxModule,
  MatChipsModule,
];
@NgModule({
  imports: [...sharedModule],
  declarations: [
    NewLineToHtmlPipe,
    FooterComponent,
    ErrorInfoComponent,
    CompanyInfoComponent,
    YouTubeUrlPipe,
    JoinAddressPipe,
    ContractTypePipe,
  ],
  exports: [
    ...sharedModule,
    // components
    CompanyInfoComponent,
    ErrorInfoComponent,
    FooterComponent,
    NewLineToHtmlPipe,
    YouTubeUrlPipe,
    ContractTypePipe,
  ],
  providers: [
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
  ],
})
export class SharedModule {}
