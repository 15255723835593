import { createReducer, on } from '@ngrx/store';
import { EquipmentDetailsResponse } from '@connected/data-access/fleet-service';
import { ConnectedActions } from './connected.actions';

export const connectedKey = 'shared-fleet-monitor';

export interface ConnectedState {
  equipmentDetails: EquipmentDetailsResponse;
  isEquipmentDetailsLoading: boolean;
  hasEquipmentDetailsFailed: boolean;
  sccURL: string;
}

export const initialConnectedState: ConnectedState = {
  equipmentDetails: {} as EquipmentDetailsResponse,
  isEquipmentDetailsLoading: true,
  hasEquipmentDetailsFailed: false,
  sccURL: '',
};

const _connectedReducer = createReducer(
  initialConnectedState,
  on(ConnectedActions.LoadEquipmentDetail, (state) => ({
    ...state,
    equipmentDetails: initialConnectedState.equipmentDetails,
    isEquipmentDetailsLoading: true,
    hasEquipmentDetailsFailed: false,
  })),
  on(ConnectedActions.LoadEquipmentDetailSuccess, (state, { payload }) => ({
    ...state,
    equipmentDetails: payload,
    isEquipmentDetailsLoading: false,
    hasEquipmentDetailsFailed: false,
  })),
  on(ConnectedActions.LoadEquipmentDetailError, (state) => ({
    ...state,
    isEquipmentDetailsLoading: false,
    hasEquipmentDetailsFailed: true,
  })),
  on(ConnectedActions.SetSCCURL, (state, { sccUrl }) => ({
    ...state,
    sccURL: sccUrl,
  })),
);

export function connectedReducer(state, action) {
  return _connectedReducer(state, action);
}
