import {
  HybrisEquipmentResponse,
  PalipediaDocumentResponseDto,
  PictureResponseDto,
} from '@connected/fleet-service/models';
import {
  GetHybrisEquipmentResponse,
  PalipediaDocumentResponse,
  PalipediaFileResponse,
  PictureResponse,
} from '@data-access/ms-equipment-generated';

import {
  GraphicArchiveItems,
  ProductTree,
  ProductTreeItemType,
  TreeDocument,
} from '@data-access/product-service';
import {
  PalipediaDocument,
  PalipediaDocumentClass,
  PalipediaDocumentClasses,
  PalipediaEquipmentWithDocuments,
  PalipediaFile,
  PalipediaGraphicsArchiveClassWithItems,
  PalipediaPicture,
  PalipediaProductOrCategoryWithDocuments,
  ProductNode,
  ProductNodeType,
} from '@features/palipedia';
import {
  ApiCategoryWithDocuments,
  ApiDocument,
  ApiDocumentPicture,
  ApiEquipmentWithDocuments,
  ApiGraphicsArchiveClassWithItems,
  ApiProductWithDocuments,
  PartnerVisibleDocumentClasses,
} from '@paldesk/shared-lib/data-access/palipedia-service-generated';

export function mapHybrisEquipmentToPalipediaEquipmentWithDocuments(
  input: GetHybrisEquipmentResponse,
): PalipediaEquipmentWithDocuments {
  return <PalipediaEquipmentWithDocuments>{
    palfingerDocuments: input.palfinger_documents.map(
      mapHybrisToPalipediaDocument,
    ),
    partnerDocuments: input.partner_documents.map(mapHybrisToPalipediaDocument),
    pictures: input.pictures.map(mapHybrisToPalipediaPicture),
    equipmentNumber: input.equipment_number,
    videoEmbeddedCode: null,
  };
}
export function mapFleetMonitorPalipediaToPalipediaEquipmentWithDocuments(
  input: HybrisEquipmentResponse,
): PalipediaEquipmentWithDocuments {
  return <PalipediaEquipmentWithDocuments>{
    palfingerDocuments:
      input.palfinger_documents?.map((document) =>
        mapFleetMonitorPalipediaToPalipediaDocument(
          document,
          input.equipment_number,
        ),
      ) || null,
    partnerDocuments:
      // eslint-disable-next-line sonarjs/no-identical-functions
      input.partner_documents?.map((document) =>
        mapFleetMonitorPalipediaToPalipediaDocument(
          document,
          input.equipment_number,
        ),
      ) || null,
    pictures: input.pictures.map(mapFleetMonitorPalipediaToPalipediaPicture),
    equipmentNumber: input.equipment_number,
    videoEmbeddedCode: null,
  };
}

export function mapPalipediaApiEquipmentToPalipediaEquipmentWithDocuments(
  input: ApiEquipmentWithDocuments,
): PalipediaEquipmentWithDocuments {
  return <PalipediaEquipmentWithDocuments>{
    palfingerDocuments:
      input.palfinger_documents?.map((d) =>
        mapPalipediaApiDocumentToPalipediaDocument(d, input.equipment_number),
      ) || null,
    partnerDocuments:
      input.partner_documents?.map((d) =>
        mapPalipediaApiDocumentToPalipediaDocument(d, input.equipment_number),
      ) || null,
    pictures:
      input.pictures?.map(mapPalipediaApiPictureToPalipediaPicture) || null,
    equipmentNumber: input.equipment_number || null,
    videoEmbeddedCode: null,
  };
}

export function mapPalipediaApiProductOrCategoryToPalipediaProductOrCategoryWithDocuments(
  input: ApiProductWithDocuments | ApiCategoryWithDocuments,
): PalipediaProductOrCategoryWithDocuments {
  return <PalipediaEquipmentWithDocuments>{
    palfingerDocuments:
      input.palfinger_documents?.map((d) =>
        mapPalipediaApiDocumentToPalipediaDocument(d),
      ) || null,
    partnerDocuments:
      input.partner_documents?.map((d) =>
        mapPalipediaApiDocumentToPalipediaDocument(d),
      ) || null,
    pictures:
      input.pictures?.map(mapPalipediaApiPictureToPalipediaPicture) || null,
    equipmentNumber: null,
    videoEmbeddedCode: null,
  };
}

export function mapTreeEquipmentToPalipediaEquipmentWithDocuments(
  input: TreeDocument,
): PalipediaEquipmentWithDocuments {
  return <PalipediaEquipmentWithDocuments>{
    palfingerDocuments:
      input.palfinger_documents?.map(mapHybrisToPalipediaDocument) || null,
    partnerDocuments:
      input.partner_documents?.map(mapHybrisToPalipediaDocument) || null,
    pictures: input.pictures?.map(mapHybrisToPalipediaPicture) || null,
    videoEmbeddedCode: input.videoEmbeddedCode,
    equipmentNumber: null,
  };
}

export function mapPalipediaApiCategoriesToPalipediaCategory(
  nodes: any[],
): ProductNode[] {
  if (!nodes) return [];
  return nodes.map((category: any) => ({
    id: category.id,
    children: category.children
      ? mapPalipediaApiCategoriesToPalipediaCategory(category.children)
      : [],
    code: category.code,
    name: category.name,
    pk: category.hybris_pk,
    type: category.type,
    currently_produced: category.currently_produced,
    hybrisType: category.type,
  }));
}

export function mapProductTreeToProductNode(
  nodes: ProductTree[],
): ProductNode[] {
  if (!nodes) return [];
  return nodes.map((category: ProductTree) => ({
    children: mapProductTreeToProductNode(category.children), // Recursive call for children
    code: category.code,
    currently_produced: category.currently_produced,
    id: category.pk,
    name: category.name,
    type:
      category.type === ProductTreeItemType.Variant
        ? ProductNodeType.Product
        : ProductNodeType.Category,
    hybrisType: category.type,
  }));
}

export function mapHybrisDocumentClassesToPalipediaDocumentClasses(
  documentClasses: any,
): PalipediaDocumentClasses {
  return <PalipediaDocumentClasses>documentClasses;
}

export function mapPalipediaDocumentClassesToPalipediaDocumentClasses(
  documentClasses: PartnerVisibleDocumentClasses,
): PalipediaDocumentClasses {
  const palipediaDocumentClasses = documentClasses.document_classes
    ? documentClasses.document_classes?.map(
        (docClass) =>
          <PalipediaDocumentClass>{
            identifier: docClass.identifier,
            name: docClass.name,
          },
      )
    : [];
  return {
    classes: palipediaDocumentClasses,
  };
}

export function mapHybrisGraphicArchiveItemToPalipediaGraphicArchiveItem(
  graphicArchiveItems: GraphicArchiveItems,
): PalipediaGraphicsArchiveClassWithItems {
  return {
    id: graphicArchiveItems.classes[0].class_pk,
    identifier: graphicArchiveItems.classes[0].class_code,
    name: graphicArchiveItems.classes[0].class_name,
    attribute_names: graphicArchiveItems.attKeys.map((key) => ({
      identifier: key,
      name: graphicArchiveItems.classes[0].attributes_names[key],
    })),
    items: graphicArchiveItems.classes[0].items.map((item) => ({
      id: item.pk,
      graphic_url: item.cad_graphic_url,
      thumbnail_url: item.thumbnail_url,
      attributes: item.attributes,
    })),
  };
}

export function mapPalipediaGraphicsArchiveToPalipediaGraphicArchiveItem(
  apiGraphicsArchiveClassWithItems: ApiGraphicsArchiveClassWithItems,
): PalipediaGraphicsArchiveClassWithItems {
  return {
    id: apiGraphicsArchiveClassWithItems.id,
    identifier: apiGraphicsArchiveClassWithItems.identifier,
    name: apiGraphicsArchiveClassWithItems.name || null,
    attribute_names: (
      apiGraphicsArchiveClassWithItems.attribute_names || []
    ).map((kvp) => ({
      identifier: kvp.key || '',
      name: kvp.value || '',
    })),
    items:
      apiGraphicsArchiveClassWithItems.items?.map((item) => ({
        id: item.id,
        graphic_url: item.graphic_url || null,
        thumbnail_url: item.thumbnail_url || null,
        attributes: item.attributes || null,
      })) || null,
  };
}

function mapHybrisToPalipediaDocument(
  documentResponse: PalipediaDocumentResponse,
): PalipediaDocument {
  return <PalipediaDocument>{
    id: documentResponse.id,
    documentClassCode: documentResponse.document_class_code,
    documentClassName: documentResponse.document_class_name,
    title: documentResponse.title,
    version: documentResponse.version,
    files: documentResponse.files?.map(mapHybrisToPalipediaFile) || null,
    fromSerialNumber: documentResponse.from_serial_number,
    untilSerialNumber: documentResponse.until_serial_number,
    companyName: documentResponse.company_name || null,
    epcDocumentConfig: null,
  };
}

function mapHybrisToPalipediaFile(
  fileResponse: PalipediaFileResponse,
): PalipediaFile {
  return {
    view: fileResponse.view,
    download: fileResponse.download,
    print: fileResponse.print,
  };
}

function mapHybrisToPalipediaPicture(
  pictureResponse: PictureResponse,
): PalipediaPicture {
  return {
    id: pictureResponse.id,
    pictureUrl: pictureResponse.picture,
    thumbnailUrl: pictureResponse.thumbnail,
    youtubeUrl: pictureResponse.youtube_url || null,
  };
}

function mapFleetMonitorPalipediaToPalipediaPicture(
  pictureResponse: PictureResponseDto,
): PalipediaPicture {
  return {
    id: pictureResponse.id,
    pictureUrl: pictureResponse.picture_url,
    thumbnailUrl: pictureResponse.thumbnail_url,
    youtubeUrl: null,
  };
}

function mapPalipediaApiDocumentToPalipediaDocument(
  documentResponse: ApiDocument,
  equipmentNumber?: string,
): PalipediaDocument {
  return <PalipediaDocument>{
    id: documentResponse.id.toString(),
    identifier: documentResponse.identifier || null,
    version: documentResponse.version || null,
    title: documentResponse.name || null,
    documentClassCode: documentResponse.document_class_identifier || null,
    documentClassName: documentResponse.document_class_name || null,
    fromSerialNumber: documentResponse.from_serial_number || null,
    untilSerialNumber: documentResponse.to_serial_number || null,
    companyName: documentResponse.company_name || null,
    files: mapPalipediaApiDocumentToPalipediaFile(documentResponse),
    epcDocumentConfig: documentResponse.epc_document_config || null,
    equipmentNumber: equipmentNumber || null,
  };
}

function mapFleetMonitorPalipediaToPalipediaDocument(
  documentResponse: PalipediaDocumentResponseDto,
  equipmentNumber?: string | null,
): PalipediaDocument {
  return <PalipediaDocument>{
    id: documentResponse.id.toString(),
    identifier: documentResponse.id || null,
    version: documentResponse.version || null,
    title: documentResponse.name || null,
    documentClassCode: documentResponse.document_class_identifier || null,
    documentClassName: documentResponse.document_class_name || null,
    fromSerialNumber: documentResponse.from_serial_number || null,
    untilSerialNumber: documentResponse.to_serial_number || null,
    companyName: documentResponse.company_name || null,
    files: mapPalipediaApiDocumentToPalipediaFile(documentResponse),
    epcDocumentConfig: null,
    equipmentNumber: equipmentNumber || null,
  };
}

function mapPalipediaApiDocumentToPalipediaFile(
  documentResponse: ApiDocument | PalipediaDocumentResponseDto,
): PalipediaFile[] {
  const viewUrls: { [key: string]: string } = {};
  documentResponse.view_urls?.forEach(
    (url) =>
      (viewUrls[url.language?.toLocaleLowerCase() || ''] = url.url || ''),
  );

  const downloadUrls: { [key: string]: string } = {};
  if (documentResponse.download_urls?.length) {
    documentResponse.download_urls?.forEach(
      (url) =>
        (downloadUrls[url.language?.toLocaleLowerCase() || ''] = url.url || ''),
    );
  } else if ('supported_languages' in documentResponse) {
    // temporary fix for download of palipedia documents
    documentResponse.supported_languages?.forEach(
      (language) =>
        (downloadUrls[language?.toLocaleLowerCase() || ''] = 'PLACEHOLDER'),
    );
  }
  return [
    {
      view: viewUrls,
      download: downloadUrls,
      print: downloadUrls,
    },
  ];
}

function mapPalipediaApiPictureToPalipediaPicture(
  pictureResponse: ApiDocumentPicture,
): PalipediaPicture {
  return {
    id: pictureResponse.id || null,
    pictureUrl: pictureResponse.picture_url || null,
    thumbnailUrl: pictureResponse.thumbnail_url || null,
    youtubeUrl: null,
  };
}
