export interface UnitConfig {
  factorToMetric: number;
  unit: string;
}

export interface MeasurementSystem {
  type: 'IMPERIAL' | 'METRIC';
  distance: UnitConfig[];
  weight: UnitConfig[];
}

export const METRIC: MeasurementSystem = {
  type: 'METRIC',
  distance: [
    {
      factorToMetric: 1,
      unit: 'm',
    },
  ],
  weight: [
    {
      factorToMetric: 1,
      unit: 'kg',
    },
  ],
};

export const IMPERIAL: MeasurementSystem = {
  type: 'IMPERIAL',
  distance: [
    {
      factorToMetric: 3.28084,
      unit: 'ft',
    },
  ],
  weight: [
    {
      factorToMetric: 2.20462,
      unit: 'lbs',
    },
  ],
};
