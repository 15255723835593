import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DsTableLoadingComponent } from './table-loading.component';
import { DsLoadingModule } from '@design-system/components/loading';

@NgModule({
  imports: [CommonModule, DsLoadingModule],
  exports: [DsTableLoadingComponent],
  declarations: [DsTableLoadingComponent],
})
export class DsTableLoadingModule {}
