import { Pipe, PipeTransform } from '@angular/core';
import {
  ConnectedContractTypeDefinition,
  DriverEquipmentResponse,
} from '@connected/data-access/fleet-service';

@Pipe({
  name: 'contractType',
})
export class ContractTypePipe implements PipeTransform {
  transform(value: DriverEquipmentResponse): string {
    const supportedContracts = [
      ConnectedContractTypeDefinition.ConnectedPlus,
      ConnectedContractTypeDefinition.ConnectedPlusEarlyAdopter,
    ];
    if (value?.active_contracts) {
      const connectedPlusContract = value.active_contracts.some((item) =>
        supportedContracts.includes(item as ConnectedContractTypeDefinition),
      );
      // should return Connected Plus only if there is supported contract, becauase they are the same
      return connectedPlusContract
        ? ConnectedContractTypeDefinition.ConnectedPlus
        : ConnectedContractTypeDefinition.None;
    } else {
      return ConnectedContractTypeDefinition.None;
    }
  }
}
