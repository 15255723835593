/**
 * MaintenanceChecklist
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum RequestState {
    SuccessfullyRequested = 'SuccessfullyRequested',
    AlreadyRequested = 'AlreadyRequested',
    NotAvailable = 'NotAvailable',
    DefaultLanguageContentRequested = 'DefaultLanguageContentRequested',
    Retrying = 'Retrying'
};

