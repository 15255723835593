import { createReducer, on } from '@ngrx/store';

import { FeatureFlagActions } from './feature-flag.actions';

export const featureFlagKey = 'featureFlag';

export interface FeatureFlagState {
  isSapIssueEnabled: boolean;
}

const initialState: FeatureFlagState = {
  isSapIssueEnabled: false,
};

const _featureFlag = createReducer(
  initialState,
  on(FeatureFlagActions.loadSapIssueFeatureError, (state) => ({
    ...state,
    isSapIssueEnabled: false,
  })),
  on(FeatureFlagActions.loadSapIssueFeatureSuccess, (state, { payload }) => ({
    ...state,
    isSapIssueEnabled: payload,
  })),
);

export function featureFlagReducer(state, action) {
  return _featureFlag(state, action);
}
