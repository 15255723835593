export interface JobPlannerForm {
  load: number;
  equipment: number;
  adjustAutomatically: boolean;
  loadCapacityArea?: number | null;
}

export interface JobPlannerStep {
  label: string;
  path: string;
  isOutrigger: boolean;
}

export interface GridCircle {
  label: number;
  radius: number;
  highlighted: boolean;
}

export class Boundaries {
  minX: number;
  maxX: number;
  minY: number;
  maxY: number;

  absMinX: number;
  absMaxX: number;
  absMinY: number;
  absMaxY: number;

  constructor(xValues: number[], yValues: number[], scaleFactor: number) {
    if (!xValues?.length || !yValues?.length)
      throw Error('empty values array invalid.');

    this.minX = Math.min(...xValues) * scaleFactor;
    this.maxX = Math.max(...xValues) * scaleFactor;
    this.minY = Math.min(...yValues) * scaleFactor;
    this.maxY = Math.max(...yValues) * scaleFactor;

    this.absMinX = Math.abs(this.minX);
    this.absMaxX = Math.abs(this.maxX);
    this.absMinY = Math.abs(this.minY);
    this.absMaxY = Math.abs(this.maxY);
  }
}

export enum OutriggersFriendlyName {
  'front_left_wheel',
  'rear_left_wheel',
  'rear_right_wheel',
  'front_right_wheel',
  'front_left_frame',
  'rear_left_frame',
  'rear_right_frame',
  'front_right_frame',
  'front_left_stabilizer',
  'front_right_stabilizer',
  'rear_left_stabilizer',
  'rear_right_stabilizer',
  'additional_stabilizer_2_left',
  'additional_stabilizer_2_right',
  'additional_stabilizer_3_left',
  'additional_stabilizer_3_right',
}

export enum LoadCapacityAreaEnum {
  Lca03 = 3,
  Lca05 = 5,
  Lca06 = 6,
  Lca08 = 8,
  Lca09 = 9,
}
