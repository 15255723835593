import { createAction, props } from '@ngrx/store';
import {
  HookloaderStatusResponse,
  LastPlatformsDataResponse,
  OperatingInfo,
  PlatformsStabilizerResponse,
  UpperStructureData,
} from '@connected/data-access/fleet-service';

export namespace CommunicationActions {
  export const ClearCommunication = createAction(
    '[Communication] CLEAR_COMMUNICATION',
  );
  export const SendOutriggerRequest = createAction(
    '[Communication] SEND_OUTTRIGGER_REQUEST',
    props<{ payload: string }>(),
  );
  export const SendOutriggerRequestSuccess = createAction(
    '[Communication] SEND_OUTTRIGGER_REQUEST_SUCCESS',
  );
  export const SendOutriggerRequestError = createAction(
    '[Communication] SEND_OUTTRIGGER_REQUEST_ERROR',
    props<{ payload: string }>(),
  );

  export const LoadHookloaderStatus = createAction(
    '[Communication] LOAD_HOOKLOADER_STATUS',
    props<{ payload: string }>(),
  );
  export const LoadHookloaderStatusSuccess = createAction(
    '[Communication] LOAD_HOOKLOADER_STATUS_SUCCESS',
    props<{ payload: HookloaderStatusResponse }>(),
  );
  export const LoadHookloaderStatusError = createAction(
    '[Communication] LOAD_HOOKLOADER_STATUS_ERROR',
  );

  export const SendHookloaderStatusRequest = createAction(
    '[Communication] SEND_HOOKLOADER_STATUS_REQUEST',
    props<{ payload: string }>(),
  );
  export const SendHookloaderStatusRequestSuccess = createAction(
    '[Communication] SEND_HOOKLOADER_STATUS_REQUEST_SUCCESS',
  );
  export const SendHookloaderStatusRequestError = createAction(
    '[Communication] SEND_HOOKLOADER_STATUS_REQUEST_ERROR',
  );

  export const LoadPpg = createAction(
    '[FleetMonitor] LOAD_PPG',
    props<{ payload: string }>(),
  );
  export const LoadPpgStatusSuccess = createAction(
    '[Communication] LOAD_PPG_STATUS_SUCCESS',
    props<{ payload: LastPlatformsDataResponse }>(),
  );
  export const LoadPpgStatusNoContent = createAction(
    '[FleetMonitor] LOAD_PPG_STATUS_NO_CONTENT',
  );
  export const LoadPpgStatusError = createAction(
    '[Communication] LOAD_PPG_STATUS_ERROR',
  );
  export const LoadPpgStabilizerSuccess = createAction(
    '[FleetMonitor] LOAD_PPG_STABILIZER_SUCCESS',
    props<{ payload: PlatformsStabilizerResponse }>(),
  );
  export const LoadPpgStabilizerError = createAction(
    '[FleetMonitor] LOAD_PPG_STABILIZER_ERROR',
  );
  export const SendPpgStabilizerRequest = createAction(
    '[FleetMonitor] SEND_PPG_STABILIZER_REQUEST',
    props<{ payload: string }>(),
  );
  export const SendPpgStabilizerRequestSuccess = createAction(
    '[FleetMonitor] SEND_PPG_STABILIZER_REQUEST_SUCCESS',
    props<{ payload: string }>(),
  );
  export const SendPpgStabilizerRequestError = createAction(
    '[FleetMonitor] SEND_PPG_STABILIZER_REQUEST_ERROR',
  );
  export const LoadOperatingInformationSuccess = createAction(
    '[FleetMonitor] LOAD_OPERATING_INFORMATION_REQUEST_SUCCESS',
    props<{ payload: OperatingInfo }>(),
  );
  export const LoadOperatingInformationError = createAction(
    '[FleetMonitor} LOAD_OPERATING_INFORMATION_REQUEST_ERROR',
  );
  export const SendPpgHeightLimitationRequest = createAction(
    '[FleetMonitor] SEND_PPG_HEIGHT_LIMITATION_REQUEST',
    props<{ equipmentNumber: string; checked: boolean }>(),
  );
  export const SendPpgHeightLimitationRequestSuccess = createAction(
    '[FleetMonitor] SEND_PPG_HEIGHT_LIMITATION_REQUEST_SUCCESS',
    props<{ payload: string }>(),
  );
  export const SendPpgHeightLimitationRequestError = createAction(
    '[FleetMonitor] SEND_PPG_HEIGHT_LIMITATION_REQUEST_ERROR',
  );
  export const SendPpgMachineLockRequest = createAction(
    '[FleetMonitor] SEND_PPG_MACHINE_LOCK_REQUEST',
    props<{ equipmentNumber: string; checked: boolean }>(),
  );
  export const SendPpgMachineLockRequestSuccess = createAction(
    '[FleetMonitor] SEND_PPG_MACHINE_LOCK_REQUEST_SUCCESS',
    props<{ payload: string }>(),
  );
  export const SendPpgMachineLockRequestError = createAction(
    '[FleetMonitor] SEND_PPG_MACHINE_LOCK_REQUEST_ERROR',
  );

  export const LoadPpgUpperStructureSuccess = createAction(
    '[FleetMonitor] LOAD_PPG_UPPER_STRUCTURE_SUCCESS',
    props<{ payload: UpperStructureData }>(),
  );
  export const LoadPpgUpperStructureError = createAction(
    '[FleetMonitor] LOAD_PPG_UPPER_STRUCTURE_ERROR',
  );
  export const SendPpgUpperStructureRequest = createAction(
    '[FleetMonitor] SEND_PPG_UPPER_STRUCTURE_REQUEST',
    props<{ payload: string }>(),
  );
  export const SendPpgUpperStructureRequestSuccess = createAction(
    '[FleetMonitor] SEND_PPG_UPPER_STRUCTURE_REQUEST_SUCCESS',
    props<{ payload: string }>(),
  );
  export const SendPpgUpperStructureRequestError = createAction(
    '[FleetMonitor] SEND_PPG_UPPER_STRUCTURE_REQUEST_ERROR',
  );
}
