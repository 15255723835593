import { NavbarItem } from '../model/navbar-item.model';

export const NAVBARITEMS: NavbarItem[] = [
  {
    routerLink: ['/dashboard'],
    icon: 'pd-equipment-status',
    label: 'menu_item.dashboard',
    public: true,
    isAlerts: false,
  },
  {
    routerLink: ['/maintenance'],
    icon: 'pd-maintenance-checklist',
    label: 'menu_item.maintentance_checklist',
    public: false,
    isAlerts: false,
  },
  {
    routerLink: ['/documents'],
    icon: 'description',
    label: 'header.search.result_documents',
    public: false,
    isAlerts: false,
  },
  {
    routerLink: ['/salesandservicesearch'],
    icon: 'pd-service-partner-search',
    label: 'menu_item.service_contact',
    public: true,
    isAlerts: false,
  },
  {
    routerLink: ['/codes'],
    icon: 'pd-alerts',
    label: 'menu_item.alerts',
    public: true,
    isAlerts: true,
  },
  {
    routerLink: ['/job-planner'],
    icon: 'pd-stability-chart',
    label: 'menu_item.palchart',
    public: false,
    isAlerts: false,
  },
  {
    routerLink: ['/system-communication'],
    icon: 'info',
    label: 'menu_item.system_communication',
    public: false,
    isAlerts: false,
  },
];
