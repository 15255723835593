import { createReducer, on } from '@ngrx/store';
import { DriverEquipmentResponse } from '@connected/data-access/fleet-service';
import { PpgViewEnum } from '../../model/ppg-view.model';
import { HeaderActions } from './header.actions';

export interface HeaderState {
  selectedEquipment?: DriverEquipmentResponse;
  equipmentList: DriverEquipmentResponse[];
  isEquipmentListLoading: boolean;
  hasEquipmentListFailed: boolean;

  hasEquipmentAssigned: boolean;
  ppgView: PpgViewEnum;
  equipmentHasPlusContract: boolean;
}

export const initialState: HeaderState = {
  selectedEquipment: undefined,
  equipmentList: [] as DriverEquipmentResponse[],
  isEquipmentListLoading: true,
  hasEquipmentListFailed: false,

  hasEquipmentAssigned: true,
  ppgView: PpgViewEnum.carrier,
  equipmentHasPlusContract: false,
};

const _headerReducer = createReducer(
  initialState,
  on(HeaderActions.LoadEquipmentList, (state) => ({
    ...state,
    equipmentList: initialState.equipmentList,
    hasEquipmentAssigned: true,
    isEquipmentListLoading: true,
    hasEquipmentListFailed: false,
  })),
  on(HeaderActions.LoadEquipmentListSuccess, (state, { payload }) => ({
    ...state,
    equipmentList: payload,
    hasEquipmentAssigned: payload.length > 0,
    isEquipmentListLoading: false,
    hasEquipmentListFailed: false,
  })),
  on(HeaderActions.LoadEquipmentListError, (state) => ({
    ...state,
    hasEquipmentAssigned: false,
    isEquipmentListLoading: false,
    hasEquipmentListFailed: true,
  })),
  on(HeaderActions.SelectEquipment, (state, { payload }) => ({
    ...state,
    selectedEquipment: payload,
    equipmentHasPlusContract: equipmentHasPlusContract(
      payload?.active_contracts,
    ),
  })),
  on(HeaderActions.SetPpgView, (state, { payload }) => ({
    ...state,
    ppgView: payload,
  })),
);

export function headerReducer(state, action): HeaderState {
  return _headerReducer(state, action);
}

function equipmentHasPlusContract(contracts: string[] | null | undefined) {
  if (!contracts) return false;

  return contracts.some(
    (contract) =>
      contract === 'ConnectedPlus' || contract === 'ConnectedPlusEarlyAdopter',
  );
}
