<!-- Value -->
<ng-container *ngIf="stabilizerConfig?.isActive; else inactive">
  <ng-container *ngIf="stabilizer | isStabilizerIsExtendable; else active">
    {{
      stabilizer
        | getStabilizerValue: stabilizerConfig
        | calculateUserUnit: 'distance' : { usedSystem: measurementSystem }
    }}
    ({{ stabilizerConfig.value * 100 | number: '1.0' }}%)
  </ng-container>
</ng-container>
<!-- Inactive -->
<ng-template #inactive>
  {{ 'connected.crane.support_inactive' | translate }}
</ng-template>
<!-- Active -->
<ng-template #active>
  {{ 'connected.crane.support_active' | translate }}
</ng-template>
