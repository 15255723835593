import { ContractsOverviewResponse } from '@connected/fleet-service/models';
import { createAction, props } from '@ngrx/store';
import { Sort } from '@angular/material/sort';
import { ContractsFilterSetup } from '../models';

export namespace ContractsActions {
  export const LoadContracts = createAction('[CONTRACTS] LOAD_CONTRACTS');
  export const LoadContractsSuccess = createAction(
    '[CONTRACTS] LOAD_CONTRACTS_SUCCESS',
    props<{ payload: ContractsOverviewResponse[] }>(),
  );
  export const LoadContractsError = createAction(
    '[CONTRACTS] LOAD_CONTRACTS_ERROR',
  );

  export const SetContractsFilter = createAction(
    '[CONTRACTS] SET_CONTRACTS_FILTER',
    props<{ payload: ContractsFilterSetup }>(),
  );
  export const SaveContractOverviewSort = createAction(
    '[CONTRACTS] SAVE_CONTRACTS_SORT',
    props<{ payload: Sort }>(),
  );
}
