import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Injectable, inject } from '@angular/core';
import { FeatureService } from '@connected/data-access/fleet-service';
import { FeatureFlagActions } from './feature-flag.actions';

@Injectable()
export class FeatureFlagEffects {
  private _actions$: Actions = inject(Actions);

  loadSapIssueFeature$ = createEffect(() =>
    this._actions$.pipe(
      ofType(FeatureFlagActions.loadSapIssueFeature),
      switchMap(() =>
        this._featureService.getFeature('isSapIssueActive').pipe(
          map((data) =>
            FeatureFlagActions.loadSapIssueFeatureSuccess({
              payload: data,
            }),
          ),
          catchError(() => [FeatureFlagActions.loadSapIssueFeatureError()]),
        ),
      ),
    ),
  );

  constructor(private _featureService: FeatureService) {}
}
