import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CommunicationService } from '@connected/data-access/fleet-service';
import { iif } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { CommunicationActions } from './communication.actions';

@Injectable()
export class CommunicationEffects {
  private actions$: Actions = inject(Actions);

  requestOutriggerInformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommunicationActions.SendOutriggerRequest),
      switchMap((action) =>
        this.communicationService.requestCraneOutriggers(action.payload).pipe(
          map(() => CommunicationActions.SendOutriggerRequestSuccess()),
          catchError((err) => [
            CommunicationActions.SendOutriggerRequestError({
              payload: err.error.Message,
            }),
          ]),
        ),
      ),
    ),
  );

  loadHookloaderStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommunicationActions.LoadHookloaderStatus),
      switchMap((payload) =>
        this.communicationService.getHookloaderStatus(payload.payload).pipe(
          map((data) =>
            CommunicationActions.LoadHookloaderStatusSuccess({
              payload: data,
            }),
          ),
          catchError(() => [CommunicationActions.LoadHookloaderStatusError()]),
        ),
      ),
    ),
  );

  sendHookloaderStatusRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommunicationActions.SendHookloaderStatusRequest),
      switchMap((action) =>
        this.communicationService.requestHookloaderStatus(action.payload).pipe(
          map(() => CommunicationActions.SendHookloaderStatusRequestSuccess()),
          catchError(() => [
            CommunicationActions.SendHookloaderStatusRequestError(),
          ]),
        ),
      ),
    ),
  );

  loadPpgStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommunicationActions.LoadPpg),
      switchMap(({ payload }) =>
        this.communicationService
          .getLastPlatformsData(payload, undefined, 'response')
          .pipe(
            map((resp) =>
              resp.status === 204
                ? CommunicationActions.LoadPpgStatusNoContent()
                : resp.status === 200
                  ? CommunicationActions.LoadPpgStatusSuccess({
                      payload: resp.body || {},
                    })
                  : CommunicationActions.LoadPpgStatusError(),
            ),
            catchError(() => [CommunicationActions.LoadPpgStatusError()]),
          ),
      ),
    ),
  );

  loadPpgStabilizer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CommunicationActions.LoadPpg,
        CommunicationActions.SendPpgStabilizerRequestSuccess,
      ),
      switchMap(({ payload }) =>
        this.communicationService.getPlatformsStabilizers(payload).pipe(
          map((data) =>
            CommunicationActions.LoadPpgStabilizerSuccess({
              payload: data,
            }),
          ),
          catchError(() => [CommunicationActions.LoadPpgStabilizerError()]),
        ),
      ),
    ),
  );

  requestPlatformsStabilizers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommunicationActions.SendPpgStabilizerRequest),
      switchMap(({ payload }) =>
        this.communicationService.requestPlatformsStabilizers(payload).pipe(
          map(() =>
            CommunicationActions.SendPpgStabilizerRequestSuccess({
              payload: payload,
            }),
          ),
          catchError(() => [
            CommunicationActions.SendPpgStabilizerRequestError(),
          ]),
        ),
      ),
    ),
  );

  loadUpperStructureInformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommunicationActions.LoadPpg),
      switchMap(({ payload }) =>
        this.communicationService.getPlatformsUpperStructure(payload).pipe(
          map((data) =>
            CommunicationActions.LoadPpgUpperStructureSuccess({
              payload: data,
            }),
          ),
          catchError(() => [CommunicationActions.LoadPpgUpperStructureError()]),
        ),
      ),
    ),
  );

  sendUpperStructureRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommunicationActions.SendPpgUpperStructureRequest),
      switchMap(({ payload }) =>
        this.communicationService.requestPlatformsUpperStructure(payload).pipe(
          mergeMap(() => [
            CommunicationActions.SendPpgUpperStructureRequestSuccess({
              payload: payload,
            }),
          ]),
          catchError(() => [
            CommunicationActions.SendPpgUpperStructureRequestError(),
          ]),
        ),
      ),
    ),
  );

  loadOperatingInformation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommunicationActions.LoadPpg),
      switchMap(({ payload }) =>
        this.communicationService.getPlatformsOperatingInfo(payload).pipe(
          map((data) =>
            CommunicationActions.LoadOperatingInformationSuccess({
              payload: data,
            }),
          ),
          catchError(() => [
            CommunicationActions.LoadOperatingInformationError(),
          ]),
        ),
      ),
    ),
  );

  sendPpgHeightLimitationRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommunicationActions.SendPpgHeightLimitationRequest),
      switchMap(({ equipmentNumber, checked }) =>
        iif(
          () => checked,
          this.communicationService.requestPlatformsHeightLimiationOn(
            equipmentNumber,
          ),
          this.communicationService.requestPlatformsHeightLimiationOff(
            equipmentNumber,
          ),
        ).pipe(
          mergeMap(() => [
            CommunicationActions.SendPpgHeightLimitationRequestSuccess({
              payload: equipmentNumber,
            }),
          ]),
          catchError(() => [
            CommunicationActions.SendPpgHeightLimitationRequestError(),
          ]),
        ),
      ),
    ),
  );

  sendPpgMachineLockRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommunicationActions.SendPpgMachineLockRequest),
      switchMap(({ equipmentNumber, checked }) =>
        iif(
          () => checked,
          this.communicationService.requestPlatformsMachineLockOn(
            equipmentNumber,
          ),
          this.communicationService.requestPlatformsMachineLockOff(
            equipmentNumber,
          ),
        ).pipe(
          mergeMap(() => [
            CommunicationActions.SendPpgMachineLockRequestSuccess({
              payload: equipmentNumber,
            }),
          ]),
          catchError(() => [
            CommunicationActions.SendPpgMachineLockRequestError(),
          ]),
        ),
      ),
    ),
  );

  constructor(private communicationService: CommunicationService) {}
}
