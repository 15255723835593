import { Pipe, PipeTransform } from '@angular/core';
import { SupportPointsLight } from '@paldesk/shared-lib/data-access/palchart-service-generated';
import { JobPlannerUtils } from '../../shared/utils/job-planner-utils';

/**
 * Returns the index of an element of an array
 */
@Pipe({
  name: 'isStabilizerIsExtendable',
  standalone: true,
})
export class IsStabilizerIsExtendablePipe implements PipeTransform {
  transform(stabilizer: SupportPointsLight) {
    if (stabilizer) {
      return JobPlannerUtils.stabilizerIsExtendable(stabilizer);
    } else {
      return;
    }
  }
}
