import { createReducer, on } from '@ngrx/store';
import {
  CraneInformation,
  PalChartConfiguration,
  PalChartCurveResult,
} from '@paldesk/shared-lib/data-access/palchart-service-generated';
import { JOBPLANNERDEFAULTSTEPS } from '../../data/job-planner-steps';
import { JobPlannerActions } from './job-planner.actions';
import {
  JobPlannerStep,
  JobPlannerForm,
  JobPlannerUtils,
} from '@connected/job-planner-v2/visualization/ui';

export interface JobPlannerState {
  steps?: JobPlannerStep[];
  data?: CraneInformation;
  isDataLoading: boolean;
  error?: { message: string; internal_error_code: number };

  config: PalChartConfiguration;
  isOutriggersLoading: boolean;
  hasOutriggersFailed: boolean;

  result: PalChartCurveResult[];
  isResultLoading: boolean;
  hasResultFailed: boolean;

  form: JobPlannerForm;
}

export const initialState: JobPlannerState = {
  data: undefined,
  isDataLoading: false,
  error: undefined,

  config: {} as PalChartConfiguration,
  isOutriggersLoading: false,
  hasOutriggersFailed: false,

  result: [],
  isResultLoading: false,
  hasResultFailed: false,

  steps: JOBPLANNERDEFAULTSTEPS.filter((x) => !x.isOutrigger),
  form: {
    load: 0,
    equipment: 0,
    adjustAutomatically: false,
    loadCapacityArea: null,
  },
};

const _jobPlannerReducer = createReducer(
  initialState,
  on(JobPlannerActions.InitializePalChart, (state) => ({
    ...state,
    data: initialState.data,
    isDataLoading: true,
    config: {} as PalChartConfiguration,
    steps: JOBPLANNERDEFAULTSTEPS.filter((x) => !x.isOutrigger),
    result: initialState.result,
    error: undefined,
  })),
  on(JobPlannerActions.InitializePalChartSuccess, (state, { payload }) => ({
    ...state,
    data: payload,
    steps: getStepsFromData(payload),
    isDataLoading: false,
    error: undefined,
  })),
  on(JobPlannerActions.InitializePalChartError, (state, { payload }) => ({
    ...state,
    data: undefined,
    steps: undefined,
    isDataLoading: false,
    error: payload,
  })),
  on(JobPlannerActions.GetOutriggers, (state) => ({
    ...state,
    isOutriggersLoading: true,
    hasOutriggersFailed: false,
  })),
  on(JobPlannerActions.GetOutriggersSuccess, (state, { payload }) => ({
    ...state,
    config: payload,
    isOutriggersLoading: false,
    hasOutriggersFailed: false,
  })),
  on(JobPlannerActions.GetOutriggersError, (state) => ({
    ...state,
    config: {} as PalChartConfiguration,
    isOutriggersLoading: true, // to show correct error
    hasOutriggersFailed: true,
  })),
  on(JobPlannerActions.ConfigurePalChart, (state, { payload }) => ({
    ...state,
    config: payload,
    result: initialState.result, // reset
    isOutriggersLoading: false,
    hasResultFailed: initialState.hasResultFailed,
    hasOutriggersFailed: initialState.hasOutriggersFailed,
  })),
  on(JobPlannerActions.SetStabilizerValue, (state, { payload }) => ({
    ...state,
    config: {
      ...state.config,
      supportPoints: updateStabilizerValueInList(
        state.config,
        payload.id,
        payload.value,
      ),
    },
    result: initialState.result, // reset
  })),
  on(JobPlannerActions.SetStabilizerState, (state, { payload }) => ({
    ...state,
    config: {
      ...state.config,
      supportPoints: updateStabilizerStateInList(
        state.config,
        payload.id,
        payload.state,
      ),
    },
    result: initialState.result, // reset
  })),
  on(JobPlannerActions.SetForm, (state, { payload }) => ({
    ...state,
    form: payload,
  })),
  on(JobPlannerActions.CalculatePalChart, (state) => ({
    ...state,
    result: initialState.result,
    isResultLoading: true,
    hasResultFailed: false,
  })),
  on(JobPlannerActions.CalculatePalChartSuccess, (state, { payload }) => ({
    ...state,
    result: payload,
    isResultLoading: false,
    hasResultFailed: false,
  })),
  on(JobPlannerActions.CalculatePalChartError, (state) => ({
    ...state,
    isResultLoading: false,
    hasResultFailed: true,
  })),
);

export function jobPlannerReducer(state, action): JobPlannerState {
  return _jobPlannerReducer(state, action);
}

function updateStabilizerValueInList(
  config: PalChartConfiguration,
  id: number,
  value: number,
) {
  return config.supportPoints?.map((item) => {
    if (item.stabilizerId !== id) {
      return item;
    }
    return {
      ...item,
      value: value,
    };
  });
}

function updateStabilizerStateInList(
  config: PalChartConfiguration,
  id: number,
  state: boolean,
) {
  return config.supportPoints?.map((item) => {
    if (item.stabilizerId !== id) {
      return item;
    }
    return {
      ...item,
      isActive: state,
    };
  });
}

function getStepsFromData(data: CraneInformation) {
  if (
    (data.supportPoints?.filter((x) =>
      JobPlannerUtils.isFrontOrBackStabilizer(x?.stabilizerId || 0),
    ).length || 0) > 0
  ) {
    return JOBPLANNERDEFAULTSTEPS;
  } else {
    return JOBPLANNERDEFAULTSTEPS.filter((x) => x.label !== 'front_rear');
  }
}
