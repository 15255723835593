import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { Language, LanguageService } from '../language.service';

/**
 * Display a language selection
 * @Input defaultLanguageCode
 * @Input availableLanguages lanuage codes which should be displayed
 * @Output languageChangeEvent
 */
@Component({
  selector: 'shared-lib-language-selection',
  templateUrl: './language-selection.component.html',
  styleUrls: ['./language-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSelectionComponent {
  @ViewChild(MatSelect)
  select: MatSelect;
  @Output()
  selectLanguage: EventEmitter<string> = new EventEmitter<string>();

  @Input() placeholder: string;
  @Input() isMaterial: boolean;

  possibleLanguages: Language[];
  private _selectedLanguageCode: string;
  private _availableLanguages: string[];
  private _defaultLanguageCode: string;

  constructor(private languageService: LanguageService) {}

  @Input()
  set availableLanguages(languages: string[]) {
    this._availableLanguages = languages;
    this.setPossibleLanguages();
  }

  @Input()
  set defaultLanguageCode(code: string) {
    if (code == null) {
      code = 'en';
    }
    this._defaultLanguageCode = code.toLowerCase();
    this._selectedLanguageCode = this.getSelectedLanguageCode();
  }

  set selectedLanguageCode(code: string) {
    this._selectedLanguageCode = code;
  }

  get selectedLanguageCode(): string {
    return this._selectedLanguageCode
      ? this._selectedLanguageCode.toLowerCase()
      : '';
  }
  /**
   * Change the current language
   */
  languageChange(newLanguageValue: string) {
    this.selectLanguage.emit(newLanguageValue);
  }
  /**
   * Set possible languages which are available for selection
   */
  private setPossibleLanguages() {
    this.possibleLanguages = [];
    this._availableLanguages.forEach((el) => {
      const foundElement = this.languageService.findLanguageByCode(
        el.toLowerCase(),
      );
      if (foundElement) {
        this.possibleLanguages.push(foundElement);
      }
    });
  }

  /**
   * Get default language code if available otherwise select english or first value of select
   */
  private getSelectedLanguageCode() {
    let code = this._defaultLanguageCode.toLowerCase();
    if (this.possibleLanguages && this.possibleLanguages.length > 0) {
      if (this.possibleLanguages.length === 1) {
        code = this.possibleLanguages[0].code.toLowerCase();
      } else {
        if (
          !this.possibleLanguages.find(
            (x) => x.code === this._defaultLanguageCode,
          )
        ) {
          if (
            this.possibleLanguages.find((x) => x.code.toLowerCase() === 'en')
          ) {
            code = 'en';
          } else {
            code = this.possibleLanguages[0].code;
          }
        }
      }
    }
    return code;
  }
}
