import { createAction, props } from '@ngrx/store';
import {
  EquipmentDetailsResponse,
  ServiceResponse,
} from '@connected/data-access/fleet-service';

export namespace DashboardActions {
  export const LoadEquipmentDetail = createAction(
    '[CONNECTED] LOAD_EQUIPMENT_DETAIL',
    props<{ payload: string }>(),
  );
  export const LoadEquipmentDetailSuccess = createAction(
    '[CONNECTED] LOAD_EQUIPMENT_DETAIL_SUCCESS',
    props<{ payload: EquipmentDetailsResponse }>(),
  );
  export const LoadEquipmentDetailError = createAction(
    '[CONNECTED] LOAD_EQUIPMENT_DETAIL_ERROR',
  );

  export const LoadService = createAction(
    '[Dashboard] LOAD_EQUIPMENT_SERVICE',
    props<{ payload: string }>(),
  );
  export const LoadServiceSuccess = createAction(
    '[Dashboard] LOAD_EQUIPMENT_SERVICE_SUCCESS',
    props<{
      service?: ServiceResponse | null;
    }>(),
  );
  export const LoadServiceError = createAction(
    '[Dashboard] LOAD_EQUIPMENT_SERVICE_ERROR',
  );
}
