<mat-dialog-content>
  <div
    class="icon-helper"
    fxLayout
    fxLayoutAlign="space-between center"
    fxLayoutGap="8px"
  >
    <div></div>
    <div></div>
    <img src="assets/icons/icon-72x72.png" />
    <div></div>
    <div></div>
  </div>
  <h2>
    {{ 'install_prompt.headline' | translate }}
  </h2>
  <p>
    {{ 'install_prompt.text' | translate }}
  </p>
</mat-dialog-content>
<mat-dialog-actions>
  {{ 'install_prompt.tab_action' | translate }}
  <img src="assets/images/share.png" />
  {{ 'install_prompt.add_action' | translate }}
</mat-dialog-actions>
