<mat-card [gaCategoryGroup]="'Header'">
  <mat-card-content>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div>
        <!-- Menu -->
        <button
          mat-icon-button
          (click)="menuClick.emit()"
          data-cy="menu-button"
          [gaClickListener]="'Open Menu'"
        >
          <mat-icon>menu</mat-icon>
        </button>
        <!-- Offline Icon -->
        <button
          mat-icon-button
          color="primary"
          (click)="offlineClick.emit()"
          *ngIf="isOffline"
          [gaClickListener]="'Click Offline Icon'"
        >
          <mat-icon>cloud_off</mat-icon>
        </button>
      </div>
      <!-- Title -->
      <div class="title">
        <span
          routerLinkActive="visible"
          [routerLink]="['/settings']"
          [gaClickListener]="'Navigate to Settings'"
        >
          {{ 'menu_item.settings' | translate }}
        </span>
        <span
          routerLinkActive="visible"
          [routerLink]="['/landing']"
          [gaClickListener]="'Navigate to Landing'"
        >
          Operator Monitor
        </span>
        <span
          routerLinkActive="visible"
          [routerLink]="['/info']"
          [gaClickListener]="'Navigate to Info'"
        >
          {{ 'menu_item.info' | translate }}
        </span>
        <span
          *ngFor="let item of navItems"
          routerLinkActive="visible"
          [routerLink]="item.routerLink"
          [gaClickListener]="'Navigate to ' + item.label"
        >
          {{ item.label | translate }}
        </span>
      </div>
      <!-- Equipment Selection -->
      <div [fxFlex]="0" fxLayoutAlign="end">
        <ng-container *ngIf="userService?.isAuthorized && hasOperatorRole()">
          <ng-container
            *pdLet="{
              data: equipmentList$ | async,
              hasFailed: hasEquipmentListFailed$ | async,
              isLoading: isEquipmentListLoading$ | async
            } as equipments"
          >
            <ng-container
              *ngIf="
                !equipments.hasFailed || equipments.data?.length;
                else error
              "
            >
              <mat-select
                *ngIf="!equipments.isLoading; else loading"
                [formControl]="equpimentSelection"
                class="equipment-selection full-width"
                data-cy="header-equipment-select"
                [gaClickListener]="'Change equipment'"
              >
                <mat-select-trigger>
                  {{ equpimentSelection.value?.carrier }}
                  <small>
                    {{ equpimentSelection.value?.equipment_number }}
                  </small>
                </mat-select-trigger>
                <mat-option
                  *ngFor="let equipment of equipments.data"
                  [value]="equipment"
                  [attr.data-cy]="
                    'equipment-' + equipment.equipment_number + '-option'
                  "
                  ><div fxLayout>
                    <ds-equipment-icon
                      [type]="equipment.equipment_type_icon"
                      [hasTelematic]="equipment.has_telematics"
                    ></ds-equipment-icon
                    >{{ equipment.carrier }} - {{ equipment.equipment_number }}
                  </div>
                </mat-option>
              </mat-select>
              <ng-template #loading>
                <div
                  fxLayout="column"
                  [fxLayoutGap]="0.25 | dsSpacing"
                  fxLayoutAlign="center end"
                >
                  <ds-loading-placeholder
                    [width]="6 | dsSpacing"
                  ></ds-loading-placeholder>
                  <ds-loading-placeholder
                    [width]="3 | dsSpacing"
                    [height]="0.5 | dsSpacing"
                  ></ds-loading-placeholder>
                </div>
              </ng-template>
            </ng-container>
            <ng-template #error>
              <opm-error-info *ngIf="equipments.hasFailed"></opm-error-info>
              <!-- if the request was successfull but no equipment was found -->
              <a
                *ngIf="equipments.data?.length === 0 && !equipments.hasFailed"
                class="no-equipment-info"
                fxLayout
                fxLayoutGap="0.5rem"
                fxLayoutAlign="start center"
                (click)="noEquipmentClick.emit()"
                [gaClickListener]="'No equipment found'"
              >
                <mat-icon>error_outline</mat-icon>
                <span
                  >{{ 'common.error.no_equipment_title' | translate }}<br />
                  <small>{{
                    'common.error.no_equipment_info' | translate
                  }}</small>
                </span>
              </a>
            </ng-template>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!userService?.isAuthorized">
          <button
            mat-icon-button
            (click)="openLanguageDialog()"
            [gaClickListener]="'Open Language Dialog'"
            data-cy="language-button"
          >
            <mat-icon>language</mat-icon>
          </button>
        </ng-container>
      </div>
    </div>
    <nav
      mat-tab-nav-bar
      [tabPanel]="tabPanel"
      *ngIf="
        location.path().includes('system-communication') &&
        hasPpgCommunicationActive
      "
    >
      <a
        mat-tab-link
        *ngFor="let view of ppgView"
        [active]="activeView === ppgViewEnum[view]"
        (click)="setPpgView(ppgViewEnum[view]); activeView = ppgViewEnum[view]"
        [gaClickListener]="'Navigate to platform' + view"
        >{{ 'connected.platform.' + view + '.title' | translate }}</a
      >
    </nav>
    <nav
      mat-tab-nav-bar
      [tabPanel]="tabPanel"
      *ngIf="location.path().includes('codes') && userService.isAuthorized"
    >
      <a
        mat-tab-link
        *ngFor="let view of statusCodeView"
        [routerLink]="view.path"
        routerLinkActive
        #rla="routerLinkActive"
        [active]="rla.isActive"
        [disabled]="
          !view.availableForNonTelematicEquipment &&
          (!hasTelematics ||
            contractType !== connectedContractType.ConnectedPlus)
        "
        [gaClickListener]="'Navigate to ' + view.label"
        attr.data-cy="{{ view.path }}-navigation"
      >
        {{ view.label | translate }}
      </a>
    </nav>
    <mat-tab-nav-panel #tabPanel> </mat-tab-nav-panel>
  </mat-card-content>
</mat-card>
