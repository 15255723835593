import { Pipe, PipeTransform } from '@angular/core';
import { SupportPoint } from '@connected/fleet-service/models';
import {
  SupportPointsLight,
  PalChartConfiguration,
} from '@paldesk/shared-lib/data-access/palchart-service-generated';
/**
 * Returns the index of an element of an array
 */
@Pipe({
  name: 'getStabilizerConfigPipe',
  standalone: true,
})
export class GetStabilizerConfigPipe implements PipeTransform {
  transform(
    stabilizer: SupportPointsLight,
    config: PalChartConfiguration,
  ): SupportPoint | undefined {
    if (config && stabilizer) {
      return config.supportPoints?.find(
        (x) => x.stabilizerId === stabilizer.stabilizerId,
      );
    } else {
      return;
    }
  }
}
