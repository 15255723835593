@for (stabilizer of stabilizers; track stabilizer) {
  <ng-container>
    <ng-container
      *pdLet="{
        stabilizer: (stabilizer | getStabilizerConfigPipe: config),
        name: OutriggersFriendlyName[(stabilizer?.stabilizerId || 0) - 1]
      } as config"
    >
      @if ((stabilizer?.stabilizerId || 0) > 4) {
        <p data-cy="stabilizer-rows">
          <span class="text-left">
            {{
              'connected.crane.outriggers_friendly_name.' + config.name
                | translate
            }}:</span
          >
          <connected-stabilizer-value
            [stabilizer]="stabilizer"
            [stabilizerConfig]="config.stabilizer"
            [measurementSystem]="measurementSystem"
            [attr.data-cy]="'status-stabilizer-' + config.name"
          >
          </connected-stabilizer-value>
        </p>
      }
    </ng-container>
  </ng-container>
}
