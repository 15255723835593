import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { EquipmentService } from '@connected/data-access/fleet-service';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HeaderActions } from './header.actions';

@Injectable()
export class HeaderEffects {
  private actions$: Actions = inject(Actions);

  loadEquipmentList$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(HeaderActions.LoadEquipmentList),
      switchMap(() =>
        this.equipmentService.getDriverEquipments().pipe(
          map((data) =>
            HeaderActions.LoadEquipmentListSuccess({
              payload: data,
            }),
          ),
          catchError(() => [HeaderActions.LoadEquipmentListError()]),
        ),
      ),
    ),
  );

  constructor(private equipmentService: EquipmentService) {}
}
