import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { TranslateModule } from '@ngx-translate/core';
import { DueDateStatus } from '@connected/data-access/fleet-service';

@Component({
  selector: 'connected-indicator',
  templateUrl: './indicator.component.html',
  styleUrls: ['./indicator.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, FlexLayoutModule, DsSpacingModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectedIndicatorComponent {
  @Input()
  isLoading: boolean;

  @Input()
  percentage: number;

  @Input()
  value: number | string;

  @Input()
  optionalValue?: number | string;

  @Input()
  unit: string;

  @Input()
  indicatorColor: DueDateStatus;

  @Input()
  isFullWidth: boolean;
}
