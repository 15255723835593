<div
  [fxLayout]="isFullWidth ? 'row-reverse' : 'column'"
  [fxLayoutGap]="0.5 | dsSpacing"
  [fxLayoutAlign]="isFullWidth ? 'space-between center' : 'space-between'"
>
  <div fxLayoutAlign="space-between" class="value">
    <span
      *ngIf="value; else showToday"
      [ngClass]="{
        'error-text': indicatorColor === 'OverDue' || indicatorColor === 'Due',
        'warning-text': indicatorColor === 'DueSoon'
      }"
      [style.min-width]="3 | dsSpacing"
      data-cy="indicator-value"
      >{{ value }} {{ unit }}</span
    >
    <span
      *ngIf="optionalValue"
      [ngClass]="{
        'error-text': indicatorColor === 'OverDue' || indicatorColor === 'Due',
        'warning-text': indicatorColor === 'DueSoon'
      }"
      >{{ optionalValue }}</span
    >
    <ng-template #showToday
      ><span class="error-text" [style.min-width]="3 | dsSpacing">{{
        'general.time_period.today' | translate
      }}</span></ng-template
    >
  </div>
  <div class="indicator">
    <div
      [attr.use-for-cy]="percentage"
      [style.width.%]="percentage"
      [ngClass]="{
        'error-bg': indicatorColor === 'OverDue' || indicatorColor === 'Due',
        'warning-bg': indicatorColor === 'DueSoon',
        neutral:
          indicatorColor === 'Unknown' || indicatorColor === 'Operational'
      }"
      class="percentage"
    ></div>
  </div>
</div>
