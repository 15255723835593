import { Pipe, PipeTransform } from '@angular/core';
import {
  JobStabilizerData,
  Coordinates,
} from '@connected/data-access/fleet-service';
import {
  PalChartConfiguration,
  SupportPointsLight,
} from '@paldesk/shared-lib/data-access/palchart-service-generated';
import { JobPlannerUtils } from '../../utils/job-planner-utils';

@Pipe({
  name: 'getConfigCoordinates',
  standalone: true,
})
export class GetConfigCoordinatesPipe implements PipeTransform {
  transform(
    supportPoint: SupportPointsLight | JobStabilizerData,
    config: PalChartConfiguration,
  ): Coordinates {
    const configPoint = config?.supportPoints?.find(
      (x) => x.stabilizerId === supportPoint.stabilizerId,
    );

    const configValue: Coordinates = {
      x: supportPoint?.x2 || 0,
      y: supportPoint?.y2 || 0,
    };

    // front and back config changes x values (up and down = is rotated)
    if (
      configPoint &&
      JobPlannerUtils.isFrontOrBackStabilizer(supportPoint?.stabilizerId || 0)
    ) {
      const relativeDistance = (supportPoint?.x2 || 0) - (supportPoint?.x || 0);
      configValue.x =
        (supportPoint?.x || 0) + relativeDistance * configPoint.value;
    } else if (configPoint) {
      // front and back config changes y values (left and right = is rotated)
      const relativeDistance = (supportPoint?.y2 || 0) - (supportPoint?.y || 0);
      configValue.y =
        (supportPoint?.y || 0) + relativeDistance * configPoint.value;
    }

    return configValue;
  }
}
