import { createReducer, on } from '@ngrx/store';
import {
  EquipmentDetailsResponse,
  ServiceResponse,
} from '@connected/data-access/fleet-service';
import { DashboardActions } from './dashboard.actions';

export interface DashboardState {
  detail?: EquipmentDetailsResponse;
  isEquipmentDetailLoading: boolean;
  hasEquipmentDetailFailed: boolean;
  service?: ServiceResponse | null;
  isServiceLoading: boolean;
  hasServiceFailed: boolean;
}

export const initialState: DashboardState = {
  detail: undefined,
  isEquipmentDetailLoading: true,
  hasEquipmentDetailFailed: false,
  service: undefined,
  isServiceLoading: true,
  hasServiceFailed: false,
};

const _dashboardReducer = createReducer(
  initialState,
  on(DashboardActions.LoadEquipmentDetail, (state) => ({
    ...state,
    detail: initialState.detail,
    isEquipmentDetailLoading: true,
    hasEquipmentDetailFailed: false,
  })),
  on(DashboardActions.LoadEquipmentDetailSuccess, (state, { payload }) => ({
    ...state,
    detail: payload,
    isEquipmentDetailLoading: false,
    hasEquipmentDetailFailed: false,
  })),
  on(DashboardActions.LoadEquipmentDetailError, (state) => ({
    ...state,
    isEquipmentDetailLoading: false,
    hasEquipmentDetailFailed: true,
  })),
  on(DashboardActions.LoadService, (state) => ({
    ...state,
    service: undefined,
    isServiceLoading: true,
    hasServiceFailed: false,
  })),
  on(DashboardActions.LoadServiceSuccess, (state, { service }) => ({
    ...state,
    service: service,
    isServiceLoading: false,
    hasServiceFailed: false,
  })),
  on(DashboardActions.LoadServiceError, (state) => ({
    ...state,
    service: undefined,
    isServiceLoading: false,
    hasServiceFailed: true,
  })),
);

export function dashboardReducer(state, action) {
  return _dashboardReducer(state, action);
}
