import { Pipe, PipeTransform } from '@angular/core';
import {
  JobEquipmentDetailsResponse,
  JobStabilizerData,
} from '@connected/data-access/fleet-service';
import {
  CraneInformation,
  SupportPointsLight,
} from '@paldesk/shared-lib/data-access/palchart-service-generated';

@Pipe({
  name: 'getSupportPointsByType',
  standalone: true,
})
export class GetSupportPointsByTypePipe implements PipeTransform {
  transform(
    data: CraneInformation | JobEquipmentDetailsResponse,
    type: 'Stabilizer' | 'Wheel',
  ): SupportPointsLight[] | JobStabilizerData[] | undefined {
    if (data && type) {
      return (
        data.supportPoints?.filter((x) => x.type?.indexOf(type) !== -1) || []
      );
    }
  }
}
