import { Pipe, PipeTransform } from '@angular/core';
import {
  SupportPointsLight,
  SupportPoint,
} from '@paldesk/shared-lib/data-access/palchart-service-generated';
import { JobPlannerUtils } from '../../shared/utils/job-planner-utils';

/**
 * Returns the index of an element of an array
 */
@Pipe({
  name: 'getStabilizerValue',
  standalone: true,
})
export class GetStabilizerValuePipe implements PipeTransform {
  transform(stabilizer: SupportPointsLight, stabilizerConfig: SupportPoint) {
    if (stabilizer && stabilizerConfig) {
      let maxValue = Math.abs(
        Math.abs(stabilizer?.y || 0) - Math.abs(stabilizer?.y2 || 0),
      );

      if (
        JobPlannerUtils.isFrontOrBackStabilizer(stabilizer?.stabilizerId || 0)
      ) {
        maxValue = Math.abs(
          Math.abs(stabilizer?.x || 0) - Math.abs(stabilizer?.x2 || 0),
        );
      }

      return (maxValue * stabilizerConfig.value) / 1000;
    } else {
      return 0;
    }
  }
}
