import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap } from '@ngrx/store';
import {
  DashboardEffects,
  dashboardReducer,
  DashboardState,
} from './dashboard';
import { HeaderEffects, headerReducer, HeaderState } from './header';
import {
  JobPlannerEffects,
  jobPlannerReducer,
  JobPlannerState,
} from './job-planner';
import {
  MaintenanceEffects,
  maintenanceReducer,
  MaintenanceState,
} from './maintenance';
import { settingsReducer, SettingsState } from './settings';
import {
  CommunicationState,
  communicationReducer,
  CommunicationEffects,
} from './communication';
import { AlertsState, alertsReducer } from './alerts';
import { AlertsEffects } from './alerts/alerts.effects';
import {
  DocumentsEffects,
  documentsReducer,
  DocumentsState,
} from './documents';
import {
  FeatureFlagState,
  featureFlagReducer,
  FeatureFlagEffects,
} from './feature-flag';
import { connectedReducer, ConnectedState } from '@connected/shared/store';

export interface OperatorMonitorState {
  dashboard: DashboardState;
  documents: DocumentsState;
  header: HeaderState;
  jobPlanner: JobPlannerState;
  settings: SettingsState;
  alerts: AlertsState;
  maintenance: MaintenanceState;
  communication: CommunicationState;
  featureFlag: FeatureFlagState;
  connected: ConnectedState;
}

export const operatorMonitorReducers: ActionReducerMap<
  OperatorMonitorState,
  any
> = {
  header: headerReducer,
  jobPlanner: jobPlannerReducer,
  dashboard: dashboardReducer,
  documents: documentsReducer,
  settings: settingsReducer,
  alerts: alertsReducer,
  maintenance: maintenanceReducer,
  communication: communicationReducer,
  featureFlag: featureFlagReducer,
  connected: connectedReducer,
};

export const OperatorMonitorEffects = [
  EffectsModule.forFeature([
    DashboardEffects,
    DocumentsEffects,
    JobPlannerEffects,
    HeaderEffects,
    AlertsEffects,
    MaintenanceEffects,
    CommunicationEffects,
    FeatureFlagEffects,
  ]),
];
