import { Pipe, PipeTransform } from '@angular/core';
import { PalChartCurveResult } from '@paldesk/shared-lib/data-access/palchart-service-generated';

@Pipe({
  name: 'getResultLines',
  standalone: true,
})
export class GetResultLinesPipe implements PipeTransform {
  transform(
    result: PalChartCurveResult,
    maxLength: number,
  ): PalChartCurveResult {
    const lengthFactor =
      maxLength / Math.sqrt(Math.pow(result.x, 2) + Math.pow(result.y, 2));

    return {
      x: result.x * lengthFactor,
      y: result.y * lengthFactor,
    } as PalChartCurveResult;
  }
}
