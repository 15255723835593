import { ApiEpcDocumentConfig } from '@paldesk/shared-lib/data-access/palipedia-service-generated';

export interface PalipediaDocument {
  id: string;
  identifier: string | null;
  title: string | null;
  version: string | null;
  files: PalipediaFile[] | null;
  documentClassCode: string;
  documentClassName: string | null;
  fromSerialNumber: string | null;
  untilSerialNumber: string | null;
  companyName: string | null;
  epcDocumentConfig: ApiEpcDocumentConfig | null;
  equipmentNumber: string | null;
}

export interface PalipediaEquipmentWithDocuments {
  palfingerDocuments: Array<PalipediaDocument>;
  partnerDocuments: Array<PalipediaDocument>;
  pictures: Array<PalipediaPicture>;
  equipmentNumber: string | null;
  videoEmbeddedCode: string | null;
}

export interface PalipediaProductOrCategoryWithDocuments {
  palfingerDocuments: Array<PalipediaDocument>;
  partnerDocuments: Array<PalipediaDocument>;
  pictures: Array<PalipediaPicture>;
  videoEmbeddedCode: string | null;
}

export interface PalipediaFile {
  view: any;
  download: any;
  print: any;
}

export interface PalipediaPicture {
  id: string | null;
  pictureUrl: string | null;
  thumbnailUrl: string | null;
  youtubeUrl: string | null;
}

export interface ProductNode {
  children: ProductNode[];
  code: string;
  currently_produced?: boolean;
  id: number;
  name?: string | null;
  type: ProductNodeType | string;
  hybrisType?: string;
}

export enum ProductNodeType {
  Category = 'category',
  Product = 'product',
}

export interface PalipediaDocumentClasses {
  classes: PalipediaDocumentClass[];
}

export interface PalipediaDocumentClass {
  identifier: string;
  name: string;
}

export interface PalipediaGraphicsArchiveClassWithItems {
  id: number;
  name: string | null;
  identifier: string;
  attribute_names: PalipediaGraphicsArchiveAttribute[];
  items: PalipediaGraphicsArchiveItem[] | null;
}

export interface PalipediaGraphicsArchiveItem {
  id: number;
  graphic_url?: string | null;
  thumbnail_url?: string | null;
  attributes?: { [key: string]: string | undefined } | null;
}

export interface PalipediaGraphicsArchiveAttribute {
  identifier: string;
  name: string;
}
