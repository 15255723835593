import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'opm-new-version-info',
  templateUrl: './new-version-info.component.html',
  styleUrls: ['./new-version-info.component.scss'],
})
export class NewVersionInfoComponent {
  constructor(public dialogRef: MatDialogRef<NewVersionInfoComponent>) {}
}
