<mat-sidenav-container>
  <mat-sidenav #sidenav>
    <opm-sidebar></opm-sidebar>
  </mat-sidenav>
  <mat-sidenav-content>
    <opm-header
      [navItems]="navItems"
      [isOffline]="isOffline"
      (menuClick)="sidenav.open()"
      (offlineClick)="showOfflineInfo()"
      (noEquipmentClick)="showNoEquipmentInfo()"
    ></opm-header>
    <div
      class="padding"
      [class.has-tab-navigation]="
        location.path().includes('system-communication') &&
        hasPpgCommunicationActive
      "
    >
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
